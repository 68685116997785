import { useEffect, useState } from 'react';
import '../App.css';
import { Alert, Button, Card, Container, Spinner, Stack } from 'react-bootstrap';
import { usePlaytestRepository } from '../repositories/PlaytestRepository';
import {
  JoinPlaytestSessionRequest,
  JoinedPlaytestSession,
  LeavePlaytestSessionRequest,
  MatchmakingPlaytestSession,
  MatchmakingPlaytestSessionDetails
} from '../../../models';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useParams } from 'react-router-dom';
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

function UserPlaytest() {
  const { username } = useParams();
  const { listUserPlaytests, listJoinedPlaytests, joinMatchmakingPlaytest, leaveMatchmakingPlaytest } =
    usePlaytestRepository();
  const [userPlaytestSessions, setUserPlaytestSessions] = useState<MatchmakingPlaytestSession[]>();
  const [listUserPlaytestsCriteria, setListUserPlaytestsCriteria] = useState<string>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (listUserPlaytestsCriteria) {
        const playtests = await listUserPlaytests(listUserPlaytestsCriteria);
        if (!ignore) {
          setUserPlaytestSessions(playtests);
          setListUserPlaytestsCriteria(undefined);
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [listUserPlaytestsCriteria, listUserPlaytests]);

  const [joinedPlaytestSessions, setJoinedPlaytestSessions] = useState<JoinedPlaytestSession[]>();
  const [listJoinedPlaytestsCriteria, setListJoinedPlaytestsCriteria] = useState<boolean>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (listJoinedPlaytestsCriteria) {
        const playtests = await listJoinedPlaytests();
        if (!ignore) {
          setJoinedPlaytestSessions(playtests);
          setListJoinedPlaytestsCriteria(undefined);
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [listJoinedPlaytestsCriteria, listJoinedPlaytests]);

  const [joinPlaytestCriteria, setJoinPlaytestCriteria] = useState<JoinPlaytestSessionRequest>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (joinPlaytestCriteria) {
        await joinMatchmakingPlaytest(joinPlaytestCriteria);
        if (!ignore) {
          setListJoinedPlaytestsCriteria(true);
          setJoinPlaytestCriteria(undefined);
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [joinPlaytestCriteria, joinMatchmakingPlaytest]);

  const [leavePlaytestCriteria, setLeavePlaytestCriteria] = useState<LeavePlaytestSessionRequest>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (leavePlaytestCriteria) {
        await leaveMatchmakingPlaytest(leavePlaytestCriteria);
        if (!ignore) {
          setListJoinedPlaytestsCriteria(true);
          setLeavePlaytestCriteria(undefined);
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [leavePlaytestCriteria, leaveMatchmakingPlaytest]);

  useEffect(() => {
    setListUserPlaytestsCriteria(username);
    setListJoinedPlaytestsCriteria(true);
  }, []);

  const sortedPlaytests = userPlaytestSessions?.sort(
    (a, b) => new Date(a.targetStartDate).valueOf() - new Date(b.targetStartDate).valueOf()
  );

  return (
    <Container className="my-3">
      <h1>@{username}</h1>
      <PlaytestCards
        playtestSessions={sortedPlaytests}
        joinedPlaytestSessions={joinedPlaytestSessions}
        onPlaytestJoined={(playtest) => {
          setJoinPlaytestCriteria({ matchmakingId: playtest.matchmakingId, inviteId: playtest.inviteId });
        }}
        onPlaytestLeft={(playtest) => {
          setLeavePlaytestCriteria({ matchmakingId: playtest.matchmakingId, inviteId: playtest.inviteId });
        }}
      />
    </Container>
  );
}

function PlaytestCards({
  playtestSessions,
  joinedPlaytestSessions,
  onPlaytestJoined,
  onPlaytestLeft
}: {
  playtestSessions?: MatchmakingPlaytestSession[];
  joinedPlaytestSessions?: JoinedPlaytestSession[];
  onPlaytestJoined: (playtest: MatchmakingPlaytestSession) => void;
  onPlaytestLeft: (playtest: MatchmakingPlaytestSession) => void;
}) {
  return (
    <>
      <Stack direction="horizontal" gap={3}>
        {!playtestSessions?.length && (
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>No Upcoming Playtests</Card.Title>
              <Card.Text>This user is not hosting any scheduled playtests.</Card.Text>
            </Card.Body>
          </Card>
        )}
        {playtestSessions?.map((playtest, index) => {
          const isJoined = joinedPlaytestSessions?.some(
            (joined) => joined.playtestSessionId === playtest.playtestSessionId
          );
          return (
            <Card style={{ width: '18rem' }} key={index}>
              <Card.Body>
                <Card.Title>{dayjs(playtest.targetStartDate).format('llll')}</Card.Title>
                <Card.Text>🕘 {playtest.durationSeconds / 60} minutes</Card.Text>
                <Card.Text>🔵 {playtest.playtestPoints} pp</Card.Text>
                {!isJoined && <Button onClick={() => onPlaytestJoined(playtest)}>Join Playtest</Button>}
                {isJoined && <Button variant='danger' onClick={() => onPlaytestLeft(playtest)}>Leave Playtest</Button>}
              </Card.Body>
            </Card>
          );
        })}
      </Stack>
    </>
  );
}

export default UserPlaytest;
