import { useEffect, useState } from 'react';
import '../App.css';
import {
  Alert,
  Button,
  Card,
  CardGroup,
  Container,
  Form,
  ListGroup,
  Modal,
  Offcanvas,
  Spinner,
  Stack,
  Tab,
  Tabs
} from 'react-bootstrap';
import { usePlaytestRepository } from '../repositories/PlaytestRepository';
import {
  JoinPlaytestSessionRequest,
  JoinedPlaytestSession,
  LeavePlaytestSessionRequest,
  ListMatchmakingPlaytestSessionsRequest,
  MatchmakingPlaytestSession,
  MatchmakingPlaytestSessionDetails,
  PlayableType
} from '../../../models';
import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { DField, DTable } from '../components/DTable';
import useLocalStorage from 'react-use-localstorage';
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const MAX_REFRESH_MINUTES = 15;

function TutorialButton() {
  const [showPlaytestTutorial, setShowPlaytestTutorial] = useLocalStorage('tutorial-playtest', 'true');
  const [show, setShow] = useState(showPlaytestTutorial === 'true');
  return (
    <>
      <div className="floating-container">
        <Button onClick={() => setShow(true)} className="floating-button">
          ?
        </Button>
      </div>
      <Offcanvas
        show={show}
        onHide={() => {
          setShow(false);
          setShowPlaytestTutorial('false');
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Playtesters Tutorial</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <h5>🔎 Finding a playtest</h5>
          <p>
            On the top right of the page, you will see any Playtests that are starting soon.
            Press the <strong>Join</strong> button to signup to participate in the playtest.
          </p>
          <p>
            In the middle of page, the next 7 days of available playtests are shown. Click on a time range to signup for that playtest.
            This will cause the playtest to appear below in Your Upcoming Playtests.
          </p>
          <Alert variant="info">
            <strong>Playtest Points (pp)</strong> are earned by participating in playtests and can be spent to increase the matchmaking speed of playtests you host.
            Points can be spent in the shop for various prizes and benefits once the shop is available.
          </Alert>
          <h5>🎲 After joining a playtest</h5>
          <p>
            Wait until the host starts the playtest. Your page will refresh and display instructions for how to connect
            to the playtest when it has started. Including which voice chat application to join, and which website to
            use.
          </p>
          <p>
            Each playtest is scheduled for a fixed duration. By joining a playtest you are agreeing to participate for
            the entire scheduled duration of the playtest. When the playtest is complete, the page will refresh and
            display a message letting you know you are okay to leave.
          </p>
          <h5>🎉 Future and past playtests</h5>
          <p>You can see your future and past playtests by clicking the tabs on the bottom half of the screen.</p>
          <p>
            Currently matchmaking only finds the next available playtest, but will eventually allow playtesters to find
            and join future playtests that match their schedule.
          </p>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function Playtest() {
  const {
    listMatchmakingPlaytests,
    joinMatchmakingPlaytest,
    listJoinedPlaytests,
    leaveMatchmakingPlaytest,
    getJoinedPlaytest
  } = usePlaytestRepository();

  const [canRefresh, setCanRefresh] = useState(true);

  const [matchmakingPlaytests, setMatchmakingPlaytests] = useState<MatchmakingPlaytestSession[]>([]);
  const [lastMatchmakingCriteria, setLastMatchmakingCriteria] = useState<ListMatchmakingPlaytestSessionsRequest>();
  const [matchmakingCriteria, setMatchmakingCriteria] = useState<ListMatchmakingPlaytestSessionsRequest>();
  const [inMatchmaking, setInMatchmaking] = useState<boolean>(false);
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (matchmakingCriteria) {
        const playtests = await listMatchmakingPlaytests(matchmakingCriteria);
        if (!ignore) {
          setMatchmakingPlaytests(playtests);
          setMatchmakingCriteria(undefined);
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [matchmakingCriteria, listMatchmakingPlaytests]);

  const [joinPlaytestCriteria, setJoinPlaytestCriteria] = useState<JoinPlaytestSessionRequest>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (joinPlaytestCriteria) {
        const result = await joinMatchmakingPlaytest(joinPlaytestCriteria);
        if (!ignore) {
          if (result.value) {
            setListJoinedPlaytestsCriteria(true);
            setJoinPlaytestCriteria(undefined);
          } else {
            // Failed to join playtest
            setJoinPlaytestCriteria(undefined);
          }

          // Refresh matchmaking
          setInMatchmaking(true);
          setMatchmakingCriteria(lastMatchmakingCriteria);
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [joinPlaytestCriteria, joinMatchmakingPlaytest]);

  const [leavePlaytestCriteria, setLeavePlaytestCriteria] = useState<LeavePlaytestSessionRequest>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (leavePlaytestCriteria) {
        await leaveMatchmakingPlaytest(leavePlaytestCriteria);
        if (!ignore) {
          setListJoinedPlaytestsCriteria(true);
          setLeavePlaytestCriteria(undefined);

          // Refresh matchmaking
          setInMatchmaking(true);
          setMatchmakingCriteria(lastMatchmakingCriteria);
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [leavePlaytestCriteria, leaveMatchmakingPlaytest]);

  const [joinedPlaytestSessions, setJoinedPlaytestSessions] = useState<JoinedPlaytestSession[]>();
  const [listJoinedPlaytestsCriteria, setListJoinedPlaytestsCriteria] = useState<boolean>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (listJoinedPlaytestsCriteria) {
        const playtests = await listJoinedPlaytests();
        if (!ignore) {
          setJoinedPlaytestSessions(playtests);
          setListJoinedPlaytestsCriteria(undefined);
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [listJoinedPlaytestsCriteria, listJoinedPlaytests]);

  const [activePlaytestSessionDetails, setActivePlaytestSessionDetails] = useState<MatchmakingPlaytestSessionDetails>();
  const [activePlaytestSessionDetailCriteria, setActivePlaytestSessionDetailCriteria] = useState<string>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (activePlaytestSessionDetailCriteria) {
        const playtest = await getJoinedPlaytest(activePlaytestSessionDetailCriteria);
        if (!ignore) {
          setActivePlaytestSessionDetails(playtest);
          setActivePlaytestSessionDetailCriteria(undefined);
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [activePlaytestSessionDetailCriteria, getJoinedPlaytest]);

  const [previousActivePlaytest, setPreviousActivePlaytest] = useState<JoinedPlaytestSession>();

  const activePlaytest = !!joinedPlaytestSessions?.length
    ? joinedPlaytestSessions
        ?.filter(
          (playtest) =>
            (playtest.status === 'ready' || playtest.status === 'started') &&
            dayjs(playtest.startDate || playtest.targetStartDate).isBefore(dayjs()) &&
            dayjs(playtest.startDate || playtest.targetStartDate)
              .add(playtest.durationSeconds, 'second')
              .isAfter(dayjs())
        )
        .sort(
          (a, b) =>
            new Date(a.startDate || a.targetStartDate).valueOf() - new Date(b.startDate || b.targetStartDate).valueOf()
        )[0]
    : undefined;

  const nextUpcomingJoinedPlaytest = !!joinedPlaytestSessions?.length
    ? joinedPlaytestSessions
        ?.filter(
          (playtest) =>
            playtest.status === 'ready' && dayjs(playtest.startDate || playtest.targetStartDate).isAfter(dayjs())
        )
        .sort(
          (a, b) =>
            new Date(a.startDate || a.targetStartDate).valueOf() - new Date(b.startDate || b.targetStartDate).valueOf()
        )[0]
    : undefined;

  const [skippedRefreshes, setSkippedRefreshes] = useState(0);

  // Refresh active playtest from server every 1 minute
  useEffect(() => {
    const comInterval = setInterval(() => {
      const targetStartDate =
        activePlaytest?.startDate || activePlaytest?.targetStartDate || nextUpcomingJoinedPlaytest?.targetStartDate;
      const minutesUntilNextPlaytest = targetStartDate
        ? dayjs(nextUpcomingJoinedPlaytest?.targetStartDate).diff(dayjs(), 'minute')
        : Number.MAX_SAFE_INTEGER;
      const refreshTargetCount =
        minutesUntilNextPlaytest > 60
          ? MAX_REFRESH_MINUTES
          : Math.round((MAX_REFRESH_MINUTES * Math.max(minutesUntilNextPlaytest - 5, 0)) / 55.0);
      if ((activePlaytest || skippedRefreshes >= refreshTargetCount) && !listJoinedPlaytestsCriteria && canRefresh) {
        setSkippedRefreshes(0);
        setActivePlaytestSessionDetails(undefined);
        setListJoinedPlaytestsCriteria(true);
      } else {
        setSkippedRefreshes((existing) => existing + 1);
      }
    }, 60_000);
    return () => clearInterval(comInterval);
  }, [activePlaytest, listJoinedPlaytestsCriteria, skippedRefreshes]);

  // Refresh matchmaking results from server every 5 minute
  useEffect(() => {
    const comInterval = setInterval(() => {
      if (inMatchmaking && !matchmakingCriteria && canRefresh) {
        setMatchmakingCriteria(lastMatchmakingCriteria);
      }
    }, 60_000 * 5); // 5 minutes
    return () => clearInterval(comInterval);
  }, [inMatchmaking, matchmakingCriteria]);

  useEffect(() => {
    if (activePlaytest && !activePlaytestSessionDetails && !activePlaytestSessionDetailCriteria) {
      setPreviousActivePlaytest(activePlaytest);
      setActivePlaytestSessionDetailCriteria(activePlaytest.playtestSessionId);
    } else if (!activePlaytest && activePlaytestSessionDetails && !activePlaytestSessionDetailCriteria) {
      setActivePlaytestSessionDetails(undefined);
    }
  }, [activePlaytest, activePlaytestSessionDetailCriteria, activePlaytestSessionDetails]);

  useEffect(() => {
    setListJoinedPlaytestsCriteria(true);

    // Disable refreshing after 3 hour on page
    const comInterval = setInterval(() => {
      setCanRefresh(false);
    }, 3_600_000 * 3); // 3 hours
    return () => clearInterval(comInterval);
  }, []);

  return (
    <>
      <TutorialButton />
      <Container>
        {!canRefresh && (
          <Alert className="mt-3" variant="info">
            You have gone inactive after 3 hours of inactivity. Refresh the page.
          </Alert>
        )}
        <Stack gap={2}>
          <Stack direction="horizontal" gap={1} className="mx-auto actions">
            <ActivePlaytest
              playtest={activePlaytestSessionDetails}
              onPlaytestLeft={(playtest) => {
                setLeavePlaytestCriteria({ matchmakingId: playtest.matchmakingId, inviteId: playtest.inviteId });
              }}
            />
            <MatchmakingPreferences
              onMatchmakingJoined={(matchmakingCriteria) => {
                setLastMatchmakingCriteria(matchmakingCriteria);
                setMatchmakingCriteria(matchmakingCriteria);
                setInMatchmaking(true);
              }}
            />
            <MatchmakingCard
              inMatchmaking={inMatchmaking}
              matchmakingPlaytests={matchmakingPlaytests}
              onMatchmakingJoined={() => {
                setLastMatchmakingCriteria(matchmakingCriteria);
                setMatchmakingCriteria(matchmakingCriteria);
                setInMatchmaking(true);
              }}
              onMatchmakingLeft={() => {
                setLastMatchmakingCriteria(undefined);
                setInMatchmaking(false);
              }}
              onPlaytestJoined={(playtest) => {
                setJoinPlaytestCriteria({ matchmakingId: playtest.matchmakingId });
              }}
            />
          </Stack>
          <MatchmakingForecast
            matchmakingPlaytests={matchmakingPlaytests}
            onPlaytestJoined={(playtest) => {
              setJoinPlaytestCriteria({ matchmakingId: playtest.matchmakingId });
            }}
          />
          <JoinedPlaytests
            joinedPlaytestSessions={joinedPlaytestSessions || []}
            onPlaytestLeft={(playtest) => {
              setLeavePlaytestCriteria({ matchmakingId: playtest.matchmakingId, inviteId: playtest.inviteId });
            }}
          />
        </Stack>
        <TerminalPlaytestModal
          activePlaytest={activePlaytest}
          previousActivePlaytest={joinedPlaytestSessions?.find(
            (session) => session.playtestSessionId === previousActivePlaytest?.playtestSessionId
          )}
          onHide={() => setPreviousActivePlaytest(undefined)}
        />
      </Container>
    </>
  );
}

function MatchmakingForecast({
  matchmakingPlaytests,
  onPlaytestJoined
}: {
  matchmakingPlaytests?: MatchmakingPlaytestSession[];
  onPlaytestJoined: (playtest: MatchmakingPlaytestSession) => void;
}) {
  const today = dayjs();
  const days: { date: Dayjs; playtests?: MatchmakingPlaytestSession[] }[] = [];
  for (let i = 0; i <= 6; i++) {
    const newDate = today.add(i, 'day');
    days.push({
      date: newDate,
      // TODO: Display 3 playtests for each day, preferring playtests at non-overlapping times, showing the highest rated playtest for each time
      playtests: matchmakingPlaytests
        ?.filter((playtest) => dayjs(playtest.targetStartDate).isSame(newDate, 'date'))
        .slice(0, 3)
    });
  }
  const calendar = days.map((day) => (
    <Card bg="Info">
      <Card.Header>{day.date.format('L')}</Card.Header>
      <ListGroup variant="flush">
        {day.playtests?.length ? (
          day.playtests.map((playtest) => (
            <ListGroup.Item>
              <Card.Link onClick={() => onPlaytestJoined(playtest)} style={{ cursor: 'pointer' }}>
                {flattenDates(undefined, playtest.targetStartDate).format('LT')} -{' '}
                {flattenDates(undefined, playtest.targetStartDate).add(playtest.durationSeconds, 'seconds').format('LT')}
              </Card.Link>
            </ListGroup.Item>
          ))
        ) : (
          <ListGroup.Item>-</ListGroup.Item>
        )}
      </ListGroup>
    </Card>
  ));
  return (
    <>
      <h3>Playtests Available This Week</h3>
      <p>Click on a time range below to sign up for a playtest at that time.</p>
      <CardGroup>{calendar}</CardGroup>
    </>
  );
}

function TerminalPlaytestModal({
  activePlaytest,
  previousActivePlaytest,
  onHide
}: {
  activePlaytest: JoinedPlaytestSession | undefined;
  previousActivePlaytest: JoinedPlaytestSession | undefined;
  onHide: () => void;
}) {
  const isPastCompletedDate =
    previousActivePlaytest?.startDate &&
    dayjs(previousActivePlaytest.startDate).add(previousActivePlaytest.durationSeconds, 'second').isBefore(dayjs());
  const isCompleted = previousActivePlaytest?.status === 'completed' || isPastCompletedDate || false;
  const isCanceled = previousActivePlaytest?.status === 'canceled';
  return (
    <Modal show={!activePlaytest && !!previousActivePlaytest && (isCanceled || isCompleted)} onHide={() => onHide()}>
      {isCompleted && (
        <>
          <Modal.Header closeButton>
            <Modal.Title>🎉 Playtest Complete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            You are now free to leave the playtest. Additional playtest points will not be awarded for continuing this
            playtest.
          </Modal.Body>
        </>
      )}
      {isCanceled && (
        <>
          <Modal.Header closeButton>
            <Modal.Title>🚫 Playtest Canceled</Modal.Title>
          </Modal.Header>
          <Modal.Body>The host has canceled the playtest.</Modal.Body>
        </>
      )}
    </Modal>
  );
}

function JoinedPlaytests({
  joinedPlaytestSessions,
  onPlaytestLeft
}: {
  joinedPlaytestSessions: JoinedPlaytestSession[];
  onPlaytestLeft: (playtest: JoinedPlaytestSession) => void;
}) {
  const [key, setKey] = useState<string>('future');
  const fields: Record<string, DField<JoinedPlaytestSession>> = {
    date: {
      header: 'Date',
      cell: (item: JoinedPlaytestSession) =>
        item.startDate || item.targetStartDate ? (
          <>📅 {dayjs(item.startDate || item.targetStartDate).format('LLLL')}</>
        ) : (
          <>📅 TBD</>
        ),
      showOnList: true
    },
    duration: {
      header: 'Duration',
      cell: (item: JoinedPlaytestSession) => <>🕘 {item.durationSeconds / 60} minutes</>,
      showOnList: true
    },
    pointsEarned: {
      header: 'Points',
      cell: (item: JoinedPlaytestSession) =>
        item.status === 'canceled' ? <>🔵 0 pp</> : <>🔵 {item.playtestPoints || '???'} pp</>,
      showOnList: true
    }
  };

  if (key === 'future') {
    fields.playtestStatusActions = {
      header: '',
      cell: (item: JoinedPlaytestSession) => {
        return (
          <Button className="mx-1" variant="danger" onClick={() => onPlaytestLeft?.(item)}>
            Leave Playtest
          </Button>
        );
      },
      showOnList: true
    };
  }

  const validPlaytestSessions = joinedPlaytestSessions.filter(
    (session) => session.startDate || session.targetStartDate
  );
  const pastPlaytestSessions = validPlaytestSessions
    .filter(
      // If the playtest should've ended before now
      (session) => {
        const startDate = dayjs(session.startDate || session.targetStartDate);
        const endDate = startDate.add(session.durationSeconds, 'seconds');

        const pastEndDate = endDate < dayjs();
        const terminalState = ['completed', 'canceled'].includes(session.status);

        return pastEndDate || terminalState;
      }
    )
    .sort(
      (a, b) =>
        new Date(b.startDate || b.targetStartDate).valueOf() - new Date(a.startDate || a.targetStartDate).valueOf()
    );
  const futurePlaytestSessions = validPlaytestSessions
    .filter((session) => !pastPlaytestSessions.includes(session))
    .sort(
      (a, b) =>
        new Date(a.startDate || a.targetStartDate).valueOf() - new Date(b.startDate || b.targetStartDate).valueOf()
    );

  return (
    <Tabs
      activeKey={key}
      onSelect={(k) => {
        if (k) setKey(k);
      }}
      className="mt-3"
    >
      <Tab eventKey="future" title="Your Upcoming Playtests">
        <DTable items={futurePlaytestSessions} fields={fields} />
      </Tab>
      <Tab eventKey="past" title="Your Past Playtests">
        <DTable items={pastPlaytestSessions} fields={fields} />
      </Tab>
    </Tabs>
  );
}

function ActivePlaytest({
  playtest,
  onPlaytestLeft
}: {
  playtest?: MatchmakingPlaytestSessionDetails;
  onPlaytestLeft: (playtest: MatchmakingPlaytestSessionDetails) => void;
}) {
  let cardContent = (
    <Card.Body>
      <Card.Title>Your Active Playtest</Card.Title>
      <Card.Text>You have no active playtest.</Card.Text>
    </Card.Body>
  );
  if (playtest) {
    const isExpired = dayjs(playtest.expirationDate).isBefore(dayjs());
    const minutesUntilExpired = Math.max(0, dayjs(playtest.expirationDate).diff(dayjs(), 'minute'));
    const minutesUntilEnd = playtest.startDate
      ? Math.max(0, dayjs(playtest.startDate).add(playtest.durationSeconds, 'second').diff(dayjs(), 'minute'))
      : -1;
    if (!isExpired) {
      cardContent = (
        <>
          <Card.Header>
            <Card.Text style={{ textAlign: 'center' }}>⏳ Expires in {minutesUntilExpired} minutes</Card.Text>
          </Card.Header>
          <Card.Body>
            <Card.Title>Your Active Playtest</Card.Title>
            <Card.Text>
              Waiting for host to start the playtest.
              <br />
              Details will appear here when the playtest is started.
            </Card.Text>
            <Card.Text>📅 {flattenDates(playtest.startDate, playtest.targetStartDate).format('llll')}</Card.Text>
            <Card.Text>🕘 {playtest.durationSeconds / 60} minutes</Card.Text>
            <Card.Text>🔵 {playtest.playtestPoints} pp</Card.Text>
            <Button variant="danger" onClick={() => onPlaytestLeft(playtest)} className="mx-1">
              Leave Playtest
            </Button>
          </Card.Body>
        </>
      );

      if (playtest.playableType === 'tabletopia') {
        cardContent = (
          <>
            {playtest.startDate && (
              <Card.Header>
                <Card.Text style={{ textAlign: 'center' }}>⏳ Ending in {minutesUntilEnd} minutes</Card.Text>
              </Card.Header>
            )}
            <Card.Body>
              <Card.Title>Your Active Playtest</Card.Title>
              <Card.Text>
                <ol>
                  <li>Click the "Open Tabletopia" button below</li>
                  <li>Mouse over one of the vacant seats and press "Take Seat"</li>
                  <li>Enter your name and press the button to "Play as Guest"</li>
                </ol>
              </Card.Text>
              <Card.Text>📅 {flattenDates(playtest.startDate, playtest.targetStartDate).format('llll')}</Card.Text>
              <Card.Text>🕘 {playtest.durationSeconds / 60} minutes</Card.Text>
              <Card.Text>🔵 {playtest.playtestPoints} pp</Card.Text>
              <Button variant="danger" onClick={() => onPlaytestLeft(playtest)} className="mx-1">
                Leave Playtest
              </Button>
              <hr />
              <Button href={playtest.tabletopiaJoinLink} target="_blank" className="mx-1">
                Open Tabletopia
              </Button>
              {playtest.rulesLink && (
                <Button href={playtest.rulesLink} target="_blank" className="mx-1">
                  Rules
                </Button>
              )}
              {playtest.discordInviteLink && (
                <Button href={playtest.discordInviteLink} target="_blank" className="mx-1">
                  Discord
                </Button>
              )}
            </Card.Body>
          </>
        );
      }

      if (playtest.playableType === 'tabletopSimulator') {
        cardContent = (
          <>
            {playtest.startDate && (
              <Card.Header>
                <Card.Text style={{ textAlign: 'center' }}>⏳ Ending in {minutesUntilEnd} minutes</Card.Text>
              </Card.Header>
            )}
            <Card.Body>
              <Card.Title>Your Active Playtest</Card.Title>
              <Card.Text>
                <ol>
                  <li>Open Tabletop Simulator</li>
                  <li>On the main menu, press the "Join" button</li>
                  <li>
                    In the search box at the top of the server browser type{' '}
                    <strong>{playtest.tabletopSimulatorRoomName}</strong>
                  </li>
                  <li>
                    Double click on <strong>{playtest.tabletopSimulatorRoomName}</strong> in the list
                  </li>
                  {playtest.tabletopSimulatorPassword && (
                    <li>
                      In the password prompt, type in <strong>{playtest.tabletopSimulatorPassword}</strong> and press ok
                    </li>
                  )}
                </ol>
              </Card.Text>
              <Card.Text>📅 {flattenDates(playtest.startDate, playtest.targetStartDate).format('llll')}</Card.Text>
              <Card.Text>🕘 {playtest.durationSeconds / 60} minutes</Card.Text>
              <Card.Text>🔵 {playtest.playtestPoints} pp</Card.Text>
              <Button variant="danger" onClick={() => onPlaytestLeft(playtest)} className="mx-1">
                Leave Playtest
              </Button>
              <hr />
              {playtest.rulesLink && (
                <Button href={playtest.rulesLink} target="_blank" className="mx-1">
                  Rules
                </Button>
              )}
              {playtest.discordInviteLink && (
                <Button href={playtest.discordInviteLink} target="_blank" className="mx-1">
                  Discord
                </Button>
              )}
            </Card.Body>
          </>
        );
      }

      if (playtest.playableType === 'playingCardsIO') {
        cardContent = (
          <>
            {playtest.startDate && (
              <Card.Header>
                <Card.Text style={{ textAlign: 'center' }}>⏳ Ending in {minutesUntilEnd} minutes</Card.Text>
              </Card.Header>
            )}
            <Card.Body>
              <Card.Title>Your Active Playtest</Card.Title>
              <Card.Text>
                <ol>
                  <li>Click the "Open PlayingCards.io" button below</li>
                  <li>A player seat will be highlighted for you, click on it to set your name</li>
                </ol>
              </Card.Text>
              <Card.Text>📅 {flattenDates(playtest.startDate, playtest.targetStartDate).format('llll')}</Card.Text>
              <Card.Text>🕘 {playtest.durationSeconds / 60} minutes</Card.Text>
              <Card.Text>🔵 {playtest.playtestPoints} pp</Card.Text>
              <Button variant="danger" onClick={() => onPlaytestLeft(playtest)} className="mx-1">
                Leave Playtest
              </Button>
              <hr />
              <Button href={playtest.playingCardsIOJoinLink} target="_blank" className="mx-1">
                Open PlayingCards.io
              </Button>
              {playtest.rulesLink && (
                <Button href={playtest.rulesLink} target="_blank" className="mx-1">
                  Rules
                </Button>
              )}
              {playtest.discordInviteLink && (
                <Button href={playtest.discordInviteLink} target="_blank" className="mx-1">
                  Discord
                </Button>
              )}
            </Card.Body>
          </>
        );
      }

      if (playtest.playableType === 'screentopGG') {
        cardContent = (
          <>
            {playtest.startDate && (
              <Card.Header>
                <Card.Text style={{ textAlign: 'center' }}>⏳ Ending in {minutesUntilEnd} minutes</Card.Text>
              </Card.Header>
            )}
            <Card.Body>
              <Card.Title>Your Active Playtest</Card.Title>
              <Card.Text>
                <ol>
                  <li>Click the "Open Screentop.gg" button below</li>
                  <li>Type your name into the box that says "Name"</li>
                  <li>Choose an open seat, then press "Join"</li>
                </ol>
              </Card.Text>
              <Card.Text>📅 {flattenDates(playtest.startDate, playtest.targetStartDate).format('llll')}</Card.Text>
              <Card.Text>🕘 {playtest.durationSeconds / 60} minutes</Card.Text>
              <Card.Text>🔵 {playtest.playtestPoints} pp</Card.Text>
              <Button variant="danger" onClick={() => onPlaytestLeft(playtest)} className="mx-1">
                Leave Playtest
              </Button>
              <hr />
              <Button href={playtest.screentopGGJoinLink} target="_blank" className="mx-1">
                Open Screentop.gg
              </Button>
              {playtest.rulesLink && (
                <Button href={playtest.rulesLink} target="_blank" className="mx-1">
                  Rules
                </Button>
              )}
              {playtest.discordInviteLink && (
                <Button href={playtest.discordInviteLink} target="_blank" className="mx-1">
                  Discord
                </Button>
              )}
            </Card.Body>
          </>
        );
      }

      if (playtest.playableType === 'itchIO') {
        cardContent = (
          <>
            {playtest.startDate && (
              <Card.Header>
                <Card.Text style={{ textAlign: 'center' }}>⏳ Ending in {minutesUntilEnd} minutes</Card.Text>
              </Card.Header>
            )}
            <Card.Body>
              <Card.Title>Your Active Playtest</Card.Title>
              <Card.Text>
                <ol>
                  <li>Click the "Open Itch.io" button below</li>
                </ol>
              </Card.Text>
              <Card.Text>📅 {flattenDates(playtest.startDate, playtest.targetStartDate).format('llll')}</Card.Text>
              <Card.Text>🕘 {playtest.durationSeconds / 60} minutes</Card.Text>
              <Card.Text>🔵 {playtest.playtestPoints} pp</Card.Text>
              <Button variant="danger" onClick={() => onPlaytestLeft(playtest)} className="mx-1">
                Leave Playtest
              </Button>
              <hr />
              <Button href={playtest.itchIOLink} target="_blank" className="mx-1">
                Open Itch.io
              </Button>
              {playtest.rulesLink && (
                <Button href={playtest.rulesLink} target="_blank" className="mx-1">
                  Rules
                </Button>
              )}
              {playtest.discordInviteLink && (
                <Button href={playtest.discordInviteLink} target="_blank" className="mx-1">
                  Discord
                </Button>
              )}
            </Card.Body>
          </>
        );
      }
    } else {
      cardContent = (
        <>
          <Card.Header>
            <Card.Text style={{ textAlign: 'center' }}>⏳ Expires in 0 minutes</Card.Text>
          </Card.Header>
          <Card.Body>
            <Card.Title>Your Playtest Expired</Card.Title>
            <Card.Text>
              The host did not start the playtest before it expired,
              <br />
              please leave the playtest.
            </Card.Text>
            <Card.Text>📅 {flattenDates(playtest.startDate, playtest.targetStartDate).format('llll')}</Card.Text>
            <Card.Text>🕘 {playtest.durationSeconds / 60} minutes</Card.Text>
            <Card.Text>🔵 {playtest.playtestPoints} pp</Card.Text>
            <Button variant="danger" onClick={() => onPlaytestLeft(playtest)} className="mx-1">
              Leave Playtest
            </Button>
          </Card.Body>
        </>
      );
    }
  }
  return (
    <div className="d-flex justify-content-center my-3">
      <Card className="mx-1">{cardContent}</Card>
    </div>
  );
}

function MatchmakingPreferences({
  onMatchmakingJoined
}: {
  onMatchmakingJoined: (matchmakingCriteria: ListMatchmakingPlaytestSessionsRequest) => void;
}) {
  const [enabledPlayableTypesString, setEnabledPlayableTypesString] = useLocalStorage(
    'enabled-playable-types',
    JSON.stringify(['tabletopia', 'playingCardsIO', 'screentopGG', 'itchIO'])
  );
  const [enabledPlayableTypes, setEnabledPlayableTypes] = useState<PlayableType[]>(
    JSON.parse(enabledPlayableTypesString)
  );

  useEffect(() => {
    onMatchmakingJoined({ playableTypes: enabledPlayableTypes });
  }, [enabledPlayableTypes]);

  return (
    <div className="d-flex justify-content-center my-3">
      <Card className="mx-1">
        <Card.Body>
          <Card.Title>Playtest Preferences</Card.Title>
          <Form.Group className="mb-3">
            <Form.Label>
              <strong>Playtest Locations</strong>
            </Form.Label>
            <Form.Check
              label="Tabletopia"
              name="tabletopia"
              checked={enabledPlayableTypes.includes('tabletopia')}
              value={'tabletopia'}
              onChange={({ target }) => {
                const updatedValues = enabledPlayableTypes.filter((playable) => playable !== target.value);
                if (target.checked) {
                  updatedValues.push(target.value as PlayableType);
                }
                setEnabledPlayableTypes(updatedValues);
                setEnabledPlayableTypesString(JSON.stringify(updatedValues));
              }}
            />
            <Form.Check
              label="PlayingCards.io"
              name="playingCardsIO"
              checked={enabledPlayableTypes.includes('playingCardsIO')}
              value={'playingCardsIO'}
              onChange={({ target }) => {
                const updatedValues = enabledPlayableTypes.filter((playable) => playable !== target.value);
                if (target.checked) {
                  updatedValues.push(target.value as PlayableType);
                }
                setEnabledPlayableTypes(updatedValues);
                setEnabledPlayableTypesString(JSON.stringify(updatedValues));
              }}
            />
            <Form.Check
              label="Screentop.gg"
              name="screentopGG"
              checked={enabledPlayableTypes.includes('screentopGG')}
              value={'screentopGG'}
              onChange={({ target }) => {
                const updatedValues = enabledPlayableTypes.filter((playable) => playable !== target.value);
                if (target.checked) {
                  updatedValues.push(target.value as PlayableType);
                }
                setEnabledPlayableTypes(updatedValues);
                setEnabledPlayableTypesString(JSON.stringify(updatedValues));
              }}
            />
            <Form.Check
              label="Itch.io"
              name="itchIO"
              checked={enabledPlayableTypes.includes('itchIO')}
              value={'itchIO'}
              onChange={({ target }) => {
                const updatedValues = enabledPlayableTypes.filter((playable) => playable !== target.value);
                if (target.checked) {
                  updatedValues.push(target.value as PlayableType);
                }
                setEnabledPlayableTypes(updatedValues);
                setEnabledPlayableTypesString(JSON.stringify(updatedValues));
              }}
            />
            <Form.Check
              label="Tabletop Simulator"
              name="tabletopSimulator"
              checked={enabledPlayableTypes.includes('tabletopSimulator')}
              value={'tabletopSimulator'}
              onChange={({ target }) => {
                const updatedValues = enabledPlayableTypes.filter((playable) => playable !== target.value);
                if (target.checked) {
                  updatedValues.push(target.value as PlayableType);
                }
                setEnabledPlayableTypes(updatedValues);
                setEnabledPlayableTypesString(JSON.stringify(updatedValues));
              }}
            />
          </Form.Group>
        </Card.Body>
      </Card>
    </div>
  );
}

function MatchmakingCard({
  inMatchmaking,
  matchmakingPlaytests,
  onMatchmakingJoined,
  onMatchmakingLeft,
  onPlaytestJoined
}: {
  inMatchmaking: boolean;
  matchmakingPlaytests?: MatchmakingPlaytestSession[];
  onMatchmakingJoined: () => void;
  onMatchmakingLeft: () => void;
  onPlaytestJoined: (playtest: MatchmakingPlaytestSession) => void;
}) {
  let cardContent = undefined;
  if (matchmakingPlaytests?.length) {
    const playtest = matchmakingPlaytests[0];
    const startDate = dayjs(playtest.targetStartDate);
    if (dayjs() > startDate) {
      cardContent = (
        <Card.Body>
          <Card.Title>Playtests Starting Soon</Card.Title>
          <Card.Text>This playtest is about to start.</Card.Text>
          <Card.Text>📅 {flattenDates(undefined, playtest.targetStartDate).format('llll')}</Card.Text>
          <Card.Text>
            🕘 {playtest.durationSeconds / 60} minutes / 🔵 {playtest.playtestPoints} pp
          </Card.Text>
          <Button onClick={() => onPlaytestJoined(playtest)}>Join Playtest</Button>
        </Card.Body>
      );
    }
  }

  if (!cardContent) {
    if (inMatchmaking) {
      cardContent = (
        <Card.Body>
          <Card.Title>Playtests Starting Soon</Card.Title>
          <Card.Text className="d-flex justify-content-center my-3">
            <Spinner animation="border" variant="primary" />
          </Card.Text>
          <Card.Text>No playtests are starting soon.</Card.Text>
          <Card.Text>Searching again in 5 minutes.</Card.Text>
          <Button variant="danger" onClick={() => onMatchmakingLeft()}>
            Cancel
          </Button>
        </Card.Body>
      );
    } else {
      cardContent = (
        <Card.Body>
          <Card.Title>Playtests Starting Soon</Card.Title>
          <Card.Text>Press the button to search for playtests.</Card.Text>
          <Button onClick={() => onMatchmakingJoined()}>Search</Button>
        </Card.Body>
      );
    }
  }
  return (
    <div className="d-flex justify-content-center my-3">
      <Card className="mx-1">{cardContent}</Card>
    </div>
  );
}

// Returns the start date if set, otherwise returns the targetStartDate
// if it is in the future, otherwise returns now
function flattenDates(startDateString?: string, targetStartDateString?: string) {
  if (startDateString) {
    return dayjs(startDateString);
  } else if (targetStartDateString) {
    const targetStartDate = dayjs(targetStartDateString);
    const now = dayjs();
    if (targetStartDate < now) {
      return now;
    } else {
      return targetStartDate;
    }
  } else {
    return dayjs()
  }
}

export default Playtest;
