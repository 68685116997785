import { useCallback, useMemo } from 'react';
import {
  Game, User, UserUpdateRequest
} from '../../../models';
import { useSimpleRepository } from './SimpleRepository';

export interface UseUserRepositoryResult {
  getUserMe: () => Promise<User | undefined>;
  updateUserMe: (request: UserUpdateRequest) => Promise<void>;
}

export const useUserRepository = (): UseUserRepositoryResult => {
  const { send } = useSimpleRepository();

  const updateUserMe = useCallback(async (request: UserUpdateRequest): Promise<void> => {
    try {
      const response = await send('PATCH', `/api/users/me`, JSON.stringify(request));
      if (!response.ok) {
        console.warn(response.body);
      }
    } catch (e) {
      console.error(e);
    }
  }, [send]);

  const getUserMe = useCallback(async (): Promise<User | undefined> => {
    try {
      const response = await send('GET', '/api/users/me');
      if (response.ok) {
        return JSON.parse(response.body) as User;
      } else {
        console.warn(response.body);
        return undefined;
      }
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }, [send]);

  return useMemo(
    () => ({
      updateUserMe,
      getUserMe,
    }),
    [
      updateUserMe,
      getUserMe,
    ]
  );
};
