import '../App.css';
import { Button, Container, Form } from 'react-bootstrap';
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from 'react';

function Login() {
  return (
    <Container>
      <h1>Login</h1>
      <LoginForm />
    </Container>
  );
}

export function LoginButton() {
  const { logout, isAuthenticated, loginWithRedirect } = useAuth0();

  return isAuthenticated ? (
    <Button variant="primary" onClick={() => logout({ 
      logoutParams: {
        returnTo: window.location.origin
      }
    })}>
      Log Out
    </Button>
  ) : (
    <Button variant="primary" onClick={() => loginWithRedirect()}>
      Log In
    </Button>
  );
}

function LoginForm() {
  return (
    <Form>
      <LoginButton />
    </Form>
  );
}

export default Login;
