import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useMemo } from 'react';
import { ErrorResponse } from '../../../models';

const LAMBDA_URI = 'https://2wns5ds9z2.execute-api.us-east-1.amazonaws.com/prod'; // 'https://2hbjgjn8ki.execute-api.us-east-2.amazonaws.com/Prod/tests'

export interface RepositoryResult<T> {
  error?: ErrorResponse | undefined;
  value: T;
}

export interface UseSimpleRepositoryResult {
  send: (method: string, path: string, body?: string) => Promise<SendResult>;
}

export interface SendResult {
  ok: boolean;
  body: string;
}

export const useSimpleRepository = (): UseSimpleRepositoryResult => {
  const { getAccessTokenSilently } = useAuth0();

  const send = useCallback(
    async (method: string, path: string, body?: string): Promise<SendResult> => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: 'https://playtest-exchange-api-gateway.com'
          }
        });
        const response = await fetch(LAMBDA_URI + path, {
          method: method,
          mode: 'cors',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: body
        });
        return { ok: response.ok, body: await response.text() };
      } catch (e) {
        console.error(e);
        return { ok: false, body: JSON.stringify(e) };
      }
    },
    [getAccessTokenSilently]
  );

  return useMemo(() => ({ send }), [send]);
};
