import React, { useState } from 'react';

import '../App.css';
import { Button, Container, Form } from 'react-bootstrap';
import { useSimpleRepository } from '../repositories/SimpleRepository';

function Test() {
  const { send } = useSimpleRepository();
  const [method, setMethod] = useState<string>('GET');
  const [path, setPath] = useState<string>('/');
  const [body, setBody] = useState<string>();
  const [responseOk, setResponseOk] = useState<boolean>();
  const [response, setResponse] = useState<string>();
  return (
    <Container>
      <Form.Group className="mb-3">
        <Form.Label>Method</Form.Label>
        <Form.Control
          value={method}
          onChange={({ target }) => {
            setMethod(target.value);
            if (target.value === 'GET') {
              setBody(undefined);
            }
          }}
        ></Form.Control>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Path</Form.Label>
        <Form.Control value={path} onChange={({ target }) => setPath(target.value)}></Form.Control>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Body</Form.Label>
        <Form.Control
          as="textarea"
          rows={10}
          value={body}
          onChange={({ target }) => setBody(target.value)}
        ></Form.Control>
      </Form.Group>
      <Button
        onClick={async () => {
          const response = await send(method, path, body);
          setResponseOk(response.ok);
          if (response.body) {
            setResponse(JSON.stringify(JSON.parse(response.body), null, 2));
          } else {
            setResponse(undefined);
          }
        }}
      >
        Send
      </Button>
      <hr />
      <Form.Group className="mb-3">
        <Form.Label>Response {responseOk === undefined ? '' : responseOk ? '✔' : '❌'}</Form.Label>
        <pre>{response}</pre>
      </Form.Group>
    </Container>
  );
}

export default Test;
