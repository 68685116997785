import { useState } from 'react';
import '../App.css';
import { Alert, Button, Carousel, Container, Form, Stack, Image } from 'react-bootstrap';
import MailchimpSubscribe, { EmailFormFields } from 'react-mailchimp-subscribe';

function Home() {
  return (
    <Container>
      <Stack gap={2} className="col-md-7 mx-auto">
        <MarketingInfo />
      </Stack>
    </Container>
  );
}

const MarketingInfo = () => {
  return (
    <>
      <br />
      <img src={process.env.PUBLIC_URL + '/logo.png'} alt='Playtest Exchange' />
      <p className='text-muted'>Home of playtesting boardgames, videogames, and more</p>
      <SubscribeEmailForm />
      <br />
      <h2>Organize and schedule playtests</h2>
      <p>Track all your playtests in a single place, and allow your playtesters to browse and sign-up for tests that work for their schedule.</p>
      <p>Invite people you know to a playtest by simply sending them a link and have all the information they need to join the playtest ready to go. No more confusion about what Discord to join, or what program to use.</p>
      <hr />
      <h2>Find playtesters automatically</h2>
      <p>List your game for matchmaking to automatically find playtesters. Trade playtests with other designers fairly and easily by joining matchmaking yourself.</p>
      <hr />
      <h2>Earn rewards for playtesting</h2>
      <p>Earn points for playtesting games and spend them to speed up matchmaking for your own games. Points can also be spent in the shop to earn various rewards.</p>
      <hr />
      <h2>Engage with communities</h2>
      <p>Continue playtesting with all your favorite communities through PlaytestExchange.com. Full support for community organized playtesting and events means communities will be able to reach more people than ever before.</p>
      <br />
    </>
  );
}

const SubscribeEmailForm = () => {
  const url = 'https://gmail.us5.list-manage.com/subscribe/post-json?u=dae205e3ed135cf4f663f4d1d&id=b15a37fcd8&f_id=0096f4e9f0&group[383706][4]=true';

  return <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <CustomForm
        status={status}
        message={message}
        onValidated={formData => subscribe(formData)}
      />
    )}
  />
}

const CustomForm = ({ status, message, onValidated }: { status: "error" | "success" | "sending" | null; message: string | Error | null; onValidated: (fields: EmailFormFields) => void }) => {
  const [email, setEmail] = useState('');
  const loading = status === 'sending';
  const disabled = loading || status === 'success';

  const submit = () =>
    email &&
    onValidated({
      EMAIL: email,
    });

  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label>Closed Beta Sign-up</Form.Label>
        {status === "sending" && (
          <Alert color='info'>
            Pending...
          </Alert>
        )}
        {status === "error" && (
          <Alert color='danger'>
            {message instanceof Error ? message.message : message}
          </Alert>
        )}
        {status === "success" && (
          <Alert color='success'>
            {message instanceof Error ? message.message : message}
          </Alert>
        )}
        <Form.Control disabled={disabled} placeholder='Email' type="email" name="EMAIL" id="mce-EMAIL" aria-describedby="EmailHelp" value={email} onChange={(e) => setEmail(e.target.value)} />
        <Form.Text id='EmailHelp' className="text-muted">Enter your email to be notified of the next closed beta test.</Form.Text>
      </Form.Group>
      <Button disabled={disabled} onClick={submit}>
        {status === 'success' && '✔'} Sign-up for Closed Beta Access
      </Button>
    </Form>
  );
};

export default Home;
