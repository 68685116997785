import '../App.css';
import { Button, Card, CloseButton, Col, Container, Form, Row, Stack } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { Organization, useItemsRepository } from '../repositories/ItemsRepository';

function buildListValueUpdater<T>(outerIndex: number, values: T[], setValues: (value: T[]) => void, updateValue: (value: T, update: any) => T) {
  return (e: { target: { value: string } }) => {
    setValues(values.map((value, index) => {
      if (index === outerIndex) {
        return updateValue(value, e.target.value);
      }
      return value;
    }))
  };
}

function FirstTimeDesigner() {
  const { getItems } = useItemsRepository<Organization>();
  const [organization, setOrganization] = useState<Organization>({ id: '', name: '' });
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if(loaded) {
      console.log('Getting tests...');
      (async () => {
        const organizations = await getItems();
        if (organizations) {
          console.log(JSON.stringify(organizations));
          if(organizations.length) {
            setOrganization(organizations[0]);
          }
        }
      })();
    }
  }, [loaded]);

  return (
    <Container>
      <Stack gap={2}>
        <CreateOrganizationForm organization={organization} setOrganization={setOrganization} />
        <hr />
        <CreateGameForm />
        <hr />
        <CreateGameVersionForm />
        <hr />
        <CreateGameVersionPlayableForm />
        <hr />
        <CreateSurveyForm />
        <hr />
        <CreatePlaytestPlanForm />
      </Stack>
    </Container>
  );
}

function CreateOrganizationForm({ organization, setOrganization }: { organization: Organization; setOrganization: (value: Organization) => void }) {
  const [validated, setValidated] = useState(!!organization.id);

  const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
    const form = event.currentTarget;

    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <h2>Create an organization</h2>
      <Form.Group className="mb-3" controlId="formOrganizationName">
        <Form.Label>Name</Form.Label>
        <Form.Control placeholder="Enter name" disabled={validated} value={organization.name} onChange={(event) => setOrganization({ ...organization, name: event.target.value })}/>
        <Form.Text className="text-muted">
          The public name of your organization, this will be shown to users on the site.
        </Form.Text>
      </Form.Group>

      {validated ?
        <Button variant='secondary' type="submit" disabled>
          Created ✔
        </Button> :
        <Button variant="primary" type="submit">
          Create
        </Button>
      }
    </Form>
  );
}

function CreateGameForm() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
    const form = event.currentTarget;

    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <h2>Create a game</h2>
      <Form.Group className="mb-3" controlId="formGameName">
        <Form.Label>Name</Form.Label>
        <Form.Control placeholder="Enter name" disabled={validated} />
        <Form.Text className="text-muted">
          The public name of your game, this will be shown to users on the site.
        </Form.Text>
      </Form.Group>

      {validated ?
        <Button variant='secondary' type="submit" disabled>
          Created ✔
        </Button> :
        <Button variant="primary" type="submit">
          Create
        </Button>
      }
    </Form>
  );
}

function CreateGameVersionForm() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
    const form = event.currentTarget;

    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <h2>Create a version</h2>
      <Form.Group className="mb-3" controlId="formGameVersionName">
        <Form.Label>Name</Form.Label>
        <Form.Control placeholder="Enter name" disabled={validated} />
        <Form.Text className="text-muted">
          The public name of the version of your game, typically represented as numbers like 1.0.0 or words like alpha, beta, etc.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formGameVersionDescription">
        <Form.Label>Description</Form.Label>
        <Form.Control placeholder="Enter description" disabled={validated} />
        <Form.Text className="text-muted">
          The private description of this version of your game, helpful to remind you what state the game is in.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formGameVersionPlayerCount">
        <Row>
          <Col>
            <Form.Label>Player Count (Min)</Form.Label>
            <Form.Control placeholder="Enter player count min" disabled={validated} />
            <Form.Text className="text-muted">
              The minimum number of players that can play the game.
            </Form.Text>
          </Col>
          <Col>
            <Form.Label>Player Count (Max)</Form.Label>
            <Form.Control placeholder="Enter player count max" disabled={validated} />
            <Form.Text className="text-muted">
              The maximum number of players that can play the game.
            </Form.Text>
          </Col>
        </Row>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formGameVersionDuration">
        <Form.Label>Duration (Per Player)</Form.Label>
        <Form.Control placeholder="Enter duration" disabled={validated} />
        <Form.Text className="text-muted">
          The number of minutes the game takes to complete per player.
        </Form.Text>
      </Form.Group>

      {validated ?
        <Button variant='secondary' type="submit" disabled>
          Created ✔
        </Button> :
        <Button variant="primary" type="submit">
          Create
        </Button>
      }
    </Form>
  );
}

function CreateGameVersionPlayableForm() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
    const form = event.currentTarget;

    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);
  };

  const [platform, setPlatform] = useState<string>();

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <h2>Create a playable</h2>
      <Form.Group className="mb-3" controlId="formGamePlayablePlatform">
        <Form.Label>Platform</Form.Label>
        <Form.Select aria-label="Playable platform" disabled={validated} value={platform} onChange={(e) => setPlatform(e.target.value)}>
          <option>Choose platform</option>
          <option value="tabletopia">Tabletopia</option>
        </Form.Select>
        <Form.Text className="text-muted">
          The platform users can play the game on.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formGamePlayableRulebook">
        <Row>
          <Col>
            <Form.Label>Rulebook (External Link)</Form.Label>
            <Form.Control placeholder="Enter rulebook link" disabled={validated} />
            <Form.Text className="text-muted">
              The link to an external rulebook for this playable.
            </Form.Text>
          </Col>
          <Col>
            <Form.Label>Rulebook included</Form.Label>
            <Form.Check type='switch' className="mb-2" disabled={validated} />
            <Form.Text className="text-muted">
              Is the rulebook included in the playable.
            </Form.Text>
          </Col>
        </Row>
      </Form.Group>

      {platform === 'tabletopia' && <Form.Group className="mb-3" controlId="formGameTabletopiaPlayLink">
        <Form.Label>Play now link</Form.Label>
        <Form.Control placeholder="Enter play now link" disabled={validated} />
        <Form.Text className="text-muted">
          The play now link for the game on tabletopia e.g. https://tabletopia.com/games/my-cool-game-aabb12/play-now
        </Form.Text>
      </Form.Group>}

      {validated ?
        <Button variant='secondary' type="submit" disabled>
          Created ✔
        </Button> :
        <Button variant="primary" type="submit">
          Create
        </Button>
      }
    </Form>
  );
}

function CreatePlaytestPlanForm() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
    const form = event.currentTarget;

    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);
  };

  // TODO: Decide at what point the designer has to choose to use matchmaking or not.
  const [planStyle, setPlanStyle] = useState<string>();
  const [planFormat, setPlanFormat] = useState<string>();

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <h2>Create a playtest plan</h2>
      <Form.Group className="mb-3" controlId="formGamePlaytestPlanStyle">
        <Form.Label>Style</Form.Label>
        <Form.Select aria-label="Playtest style" disabled={validated} value={planStyle} onChange={(e) => setPlanStyle(e.target.value)}>
          <option value="">Choose style</option>
          <option value="manual">Manual</option>
          <option value="matchmade">Matchmade</option>
        </Form.Select>
        <Form.Text className="text-muted">
          Controls how the playtests are scheduled, and how playtesters are found.
        </Form.Text>
      </Form.Group>

      {planStyle === 'manual' &&
        <>
          <p>
            <strong>Manual</strong> playtests provide a sign-up link for users to join an active or scheduled playtest.
          </p>
        </>}

        {planStyle === 'matchmade' &&
        <>
          <p>
            <strong>Matchmade</strong> playtests appear on the Playtest Exchange homepage and automatically find users to join.
          </p>
        </>}

      <Form.Group className="mb-3" controlId="formGamePlaytestPlanFormat">
        <Form.Label>Format</Form.Label>
        <Form.Select aria-label="Playtest format" disabled={validated} value={planFormat} onChange={(e) => setPlanFormat(e.target.value)}>
          <option value="">Choose format</option>
          <option value="blind">Blind</option>
          <option value="guided">Guided</option>
        </Form.Select>
        <Form.Text className="text-muted">
          Controls how feedback is collected, and who is involved in the playtest.
        </Form.Text>
      </Form.Group>

      {planFormat === 'blind' &&
        <>
          <p>
            <strong>Blind</strong> playtests do not require the designer to be present, can happen in parallel, and will provide the designer with feedback in the form of survey results.
          </p>
        </>}

        {planFormat === 'guided' &&
        <>
          <p>
            <strong>Guided</strong> playtests require the designer to be present, take place one at a time, and allow the designer to collect feedback live or optionally in the form of survey results.
          </p>
        </>}

      <Form.Group className="mb-3" controlId="formGamePlaytestPlanTargetPlayerCount">
        <Form.Label>Target player count</Form.Label>
        <Form.Control placeholder="Enter target player count" disabled={validated} />
        <Form.Text className="text-muted">
          The number of players to schedule for the playtest. This number must be within the game's minimum and maximum player count.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formGamePlaytestPlanTargetPlayerCountVarianceAllowed">
        <Form.Label>Allow varied player counts</Form.Label>
        <Form.Check type='switch' className="mb-2" disabled={validated} />
        <Form.Text className="text-muted">
          Are playtests allowed to have more or less players than the target player count?
        </Form.Text>
      </Form.Group>

      {validated ?
        <Button variant='secondary' type="submit" disabled>
          Created ✔
        </Button> :
        <Button variant="primary" type="submit">
          Create
        </Button>
      }
    </Form>
  );
}

function CreateSurveyForm() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
    const form = event.currentTarget;

    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);
  };

  const [questions, setQuestions] = useState([{ question: '', answerType: 'text' }]);

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <h2>(Optional) Create a survey</h2>
      <Form.Group className="mb-3" controlId="formSurvey">
        <Form.Label>Name</Form.Label>
        <Form.Control placeholder="Enter name" disabled={validated} />
        <Form.Text className="text-muted">
          The private name of your survey, helpful when using the survey for multiple games.
        </Form.Text>
      </Form.Group>

      <Stack gap={3} className="mb-3">
        {questions.map((question, index) =>
          <Card>
            <Card.Header>
              <CloseButton disabled={validated} onClick={() => setQuestions(questions.filter((innerQuestion, innerIndex) => innerIndex !== index))} />
            </Card.Header>
            <Card.Body>
              <Form.Group className="mb-3" controlId="formSurveyQuestion">
                <Form.Label>Question #{index + 1}</Form.Label>
                <Form.Control placeholder="Enter question text" disabled={validated} value={question.question} onChange={buildListValueUpdater(index, questions, setQuestions, (q, u) => ({ ...q, question: u }))} />
                <Form.Text className="text-muted">
                  The question to ask the playtester.
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="formSurveyAnswerType">
                <Form.Label>Answer type</Form.Label>
                <Form.Select aria-label="Survey question answer type" disabled={validated} value={question.answerType} onChange={buildListValueUpdater(index, questions, setQuestions, (q, u) => ({ ...q, answerType: u }))}>
                  <option value="">Choose style</option>
                  <option value="text">Text</option>
                </Form.Select>
                <Form.Text className="text-muted">
                  Controls the format that answers are collected in.
                </Form.Text>
              </Form.Group>
            </Card.Body>
          </Card>)}

        {validated ?
          <Button variant='secondary' type="submit" disabled>
            Add Question
          </Button> :
          <Button variant="info" onClick={() => setQuestions([...questions, { question: '', answerType: 'text' }])}>
            Add Question
          </Button>
        }
      </Stack>

      {validated ?
        <Button variant='secondary' type="submit" disabled>
          Created ✔
        </Button> :
        <Button variant="primary" type="submit">
          Create
        </Button>
      }
    </Form>
  );
}

export default FirstTimeDesigner;
