import '../App.css';
import { Button, Container, Form } from 'react-bootstrap';
import { useUserRepository } from '../repositories/UserRepository';
import { User, UserUpdateRequest } from '../../../models';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { DForm } from '../components/DTable';
import { Link } from 'react-router-dom';
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

function Profile() {
  const { getUserMe, updateUserMe } = useUserRepository();

  const [userMe, setUserMe] = useState<User>();
  const [getUserMeCriteria, setGetUserMeCriteria] = useState<boolean>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (getUserMeCriteria) {
        const response = await getUserMe();
        if (!ignore) {
          setUserMe(response);
          setGetUserMeCriteria(undefined);
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [getUserMeCriteria, getUserMe]);

  const [updateUserMeCriteria, setUpdateUserMeCriteria] = useState<UserUpdateRequest>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (updateUserMeCriteria) {
        await updateUserMe(updateUserMeCriteria);
        if (!ignore) {
          setUserMe((data) => ({
            joinDate: new Date().toISOString(),
            playtestPoints: 0,
            ...data,
            ...updateUserMeCriteria,
          }));
          setUpdateUserMeCriteria(undefined);
          setIsLoading(false);
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [updateUserMeCriteria, updateUserMe]);

  const DEFAULT_USER_FORM_DATA = { displayName: '', username: '' };
  const [userFormData, setUserFormData] = useState<UserUpdateRequest>(DEFAULT_USER_FORM_DATA);
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    setGetUserMeCriteria(true);
  }, []);

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;

    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);
      setIsLoading(true);
      if (!userFormData.username) {
        delete userFormData.username
      }
      if (!userFormData.displayName) {
        delete userFormData.displayName
      }
      setUpdateUserMeCriteria(userFormData);
      setUserFormData(DEFAULT_USER_FORM_DATA);
    }
  };

  return (
    <Container className="my-3">
      <h1>
        {userMe?.displayName || 'User'}{' '}
        {userMe?.username ? <Link to={`/users/${userMe?.username}`}>@{userMe?.username || 'user'}</Link> : ''}
      </h1>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Joined Date</Form.Label>
          <Form.Control plaintext readOnly value={dayjs(userMe?.joinDate).format('llll')} />
          <Form.Text className="text-muted">The date you joined PlaytestExchange.com.</Form.Text>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Playtest Points</Form.Label>
          <Form.Control plaintext readOnly value={`🔵 ${userMe?.playtestPoints || 0} pp`} />
          <Form.Text className="text-muted">The number of playtest points you currently have.</Form.Text>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Display Name</Form.Label>
          <Form.Control
            value={userFormData.displayName}
            disabled={isLoading}
            placeholder={userMe?.displayName}
            minLength={5}
            maxLength={35}
            onChange={({ target }) => {
              setUserFormData((data) => ({ ...data, displayName: target.value }));
            }}
          />
          <Form.Control.Feedback type="invalid">Username must be between 5 and 35 characters.</Form.Control.Feedback>
          <Form.Text className="text-muted">How you prefer to be addressed in communications.</Form.Text>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Username</Form.Label>
          <Form.Control
            value={userFormData.username}
            disabled={isLoading}
            placeholder={userMe?.username}
            minLength={5}
            maxLength={35}
            onChange={({ target }) => {
              setUserFormData((data) => ({ ...data, username: target.value }));
            }}
          />
          <Form.Control.Feedback type="invalid">Username must be between 5 and 35 characters.</Form.Control.Feedback>
          <Form.Text className="text-muted">
            The unique name used to find you on PlaytestExchange.com. Users can find your hosted playtests by navigating
            to <Link to={`/users/${userMe?.username}`}>users/{userMe?.username}</Link>
          </Form.Text>
        </Form.Group>
        <Button variant="primary" disabled={isLoading} type="submit">
          Save Changes
        </Button>
      </Form>
    </Container>
  );
}

export default Profile;
