import { useCallback, useMemo } from 'react';
import { ErrorResponse, Game } from '../../../models';
import { RepositoryResult, useSimpleRepository } from './SimpleRepository';

export interface UseGameRepositoryResult {
  listGames: () => Promise<Game[]>;
  createGame: (game: Partial<Game>) => Promise<RepositoryResult<Game | undefined>>;
  deleteGame: (gameId: string) => Promise<void>;
  updateGame: (game: Partial<Game>) => Promise<void>;
}

export const useGameRepository = (): UseGameRepositoryResult => {
  const { send } = useSimpleRepository();

  const listGames = useCallback(async (): Promise<Game[]> => {
    try {
      const response = await send('GET', '/api/users/me/games');
      if (response.ok) {
        return JSON.parse(response.body) as Game[];
      } else {
        console.warn(response.body);
        return [];
      }
    } catch (e) {
      console.error(e);
      return [];
    }
  }, [send]);

  const createGame = useCallback(
    async (game: Partial<Game>): Promise<RepositoryResult<Game | undefined>> => {
      try {
        const response = await send('POST', '/api/users/me/games', JSON.stringify(game));
        if (response.ok) {
          return { value: JSON.parse(response.body) as Game };
        } else {
          console.warn(response.body);
          return { value: undefined, error: JSON.parse(response.body) as ErrorResponse };
        }
      } catch (e) {
        console.error(e);
        return { value: undefined, error: undefined };
      }
    },
    [send]
  );

  const updateGame = useCallback(
    async (game: Partial<Game>): Promise<void> => {
      try {
        const { gameId, ...gameWithoutId } = game;
        const response = await send('PATCH', `/api/users/me/games/${gameId}`, JSON.stringify(gameWithoutId));
        if (!response.ok) {
          console.warn(response.body);
        }
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    [send]
  );

  const deleteGame = useCallback(
    async (gameId: string): Promise<void> => {
      try {
        const response = await send('DELETE', `/api/users/me/games/${gameId}`);
        if (!response.ok) {
          console.warn(response.body);
        }
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    [send]
  );

  return useMemo(
    () => ({
      listGames,
      createGame,
      updateGame,
      deleteGame
    }),
    [listGames, createGame, updateGame, deleteGame]
  );
};
