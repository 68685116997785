import React from 'react';

import '../App.css';
import { Container, Stack, Accordion } from 'react-bootstrap';

function Info() {
  return (
    <Container>
      <Stack gap={2} className="col-md-7 mx-auto">
        <FAQ />
      </Stack>
    </Container>
  );
}

const FAQ = () => {
  return (
    <>
      <br />
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>What upcoming features are planned?</Accordion.Header>
          <Accordion.Body>
            You can see our rough{' '}
            <a href="https://trello.com/b/y0SYjoJj/playtest-exchange" target="_blank" rel="noreferrer">
              development roadmap on trello
            </a>
            {', '}a simple task organization website. This will give you an idea of what features are currently planned,
            and in what order they will likely be released.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Where can I ask questions or provide feedback?</Accordion.Header>
          <Accordion.Body>
            <p>
              If you don't see an answer to your question on the FAQ here, you can ask it by filling out{' '}
              <a href="https://forms.gle/cGpKBKP463YGmSk58" target="_blank" rel="noreferrer">
                this google form
              </a>{' '}
              . We will periodically update the FAQ with answers to questions we receive.
            </p>
            <p>
              To provide feedback on the site, roadmap, or to report bugs, please fill out{' '}
              <a href="https://forms.gle/cGpKBKP463YGmSk58" target="_blank" rel="noreferrer">
                the google form here
              </a>
              .
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>How much will it cost to use?</Accordion.Header>
          <Accordion.Body>
            <p>
              Currently the site is planned to be be free to use, with some optional purchases. Designers that want to
              do a large number of playtests each month can buy boosts for their playtests, increasing the speed of
              finding players during matchmaking.
            </p>
            <p>
              We may also offer an optional monthly subscription to support maintaining the site and paying for hosting
              costs.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Is this for organizing playtests or are people uploading their games to Playtest Exchange?</Accordion.Header>
          <Accordion.Body>
            <p>
            Playtest Exchange is just for organizing playtests, you will still use your favorite digital platform to host the game.
            There will be simple integrations to the various digital tabletops at first.
            </p>
            <p>
            For example, on Playtest Exchange you would setup a game to playtest by providing a link to your game on Tabletopia, 
            and when it has found players for you, it would direct them to Tabletopia to actually play the game.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Will you be able to do blind (unmoderated / unattended) playtesting?</Accordion.Header>
          <Accordion.Body>
            <p>
            Both blind and hosted playtests will be supported. You will also be able to create surveys to capture feedback from blind playtests.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>Will you be able to do in-person playtesting?</Accordion.Header>
          <Accordion.Body>
            <p>
            Organizing in-person playtests will be supported. For the safety of our users, the current idea is to allow in-person playtests to 
            choose a verified local game store, or other public place as the location of the playtest.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default Info;
