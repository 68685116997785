import { useEffect, useState } from 'react';
import '../App.css';
import { Alert, Button, Collapse, Container, Modal, Offcanvas } from 'react-bootstrap';
import { DForm, DTableWithActions } from '../components/DTable';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useGameRepository } from '../repositories/GameRepository';
import {
  CreatePlaytestSessionRequest,
  ErrorResponse,
  Game,
  PlaytestSession,
  PlaytestSessionPatch
} from '../../../models';
import { usePlaytestRepository } from '../repositories/PlaytestRepository';
import useLocalStorage from 'react-use-localstorage';
dayjs.extend(localizedFormat);

function TutorialButton() {
  const [showDesignerTutorial, setShowDesignerTutorial] = useLocalStorage('tutorial-designer', 'true');
  const [show, setShow] = useState(showDesignerTutorial === 'true');
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  return (
    <>
      <div className="floating-container">
        <Button onClick={() => setShow(true)} className="floating-button">
          ?
        </Button>
      </div>
      <Offcanvas
        show={show}
        onHide={() => {
          setShow(false);
          setShowDesignerTutorial('false');
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Designers Tutorial</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <h5>♟ Creating a game</h5>
          <p>
            Press the <strong>Create</strong> button to the right of Games to create a new game.
          </p>
          <p>
            A game must have a name and a type. The name of the game will not be shown to other users. Both the name and
            type can be changed at any time.
          </p>
          <h5>🧪 Schedule a playtest</h5>
          <p>
            Press the <strong>Create</strong> button to the right of Playtest Sessions to schedule a playtest.
          </p>
          <p>
            If you want to quickly schedule a playtest, choose which Game the playtest is for then press{' '}
            <strong>Save Changes</strong> at the bottom. This will schedule a playtest on Tabletopia for 1 hour, starting now, with up
            to 4 players.{' '}
            <a
              href="#"
              onClick={(event) => {
                event.preventDefault();
                setShowMoreInfo(!showMoreInfo);
              }}
            >
              More Info
            </a>
          </p>
          <Collapse in={showMoreInfo}>
            <div>
              <p>
                <strong>Version</strong> - Which version of the game is being playtested.
              </p>
              <p>
                <strong>Duration</strong> - How long the playtest will last, including time for feedback. Max 3 hours.
              </p>
              <p>
                <strong>Target Start Date / Time</strong> - The playtest start time assuming enough playtesters have
                joined.
              </p>
              <p>
                <strong>Players</strong> - The number of players that may join this playtest. Max 8 players.
              </p>
              <p>
                <strong>Matchmaking Tier</strong> - The priority of this playtest during matchmaking. Higher tiers will
                receive players first, but cost playtest points based on the duration and number of players. Playtest
                points will be deducted from the users balance when scheduling the playtest, and the difference will be
                refunded if the playtest is completed sooner or with fewer players.
              </p>
              <p>
                <strong>Type</strong> - The format of the playtest. Guided playtests have a host present to teach and
                facilitate running the game.
              </p>
              <p>
                <strong>Discord Invite Link</strong> - When using Discord for voice communication during the playtest,
                this is the link to invite a user to the server. Should look like{' '}
                <em>https://discord.gg/rANDomLetTeRs</em>.
              </p>
              <p>
                <strong>Use Built-In Communication</strong> - If the playtest location has a built in way to communicate
                between players.
              </p>
              <p>
                <strong>Rules Link</strong> - Link to a rules document.
              </p>
              <p>
                <strong>Use Built-In Rules</strong> - If the playtest location has a rules document included.
              </p>
              <p>
                <strong>Location</strong> - The website or program used to run the playtest. After choosing a location,
                additional fields will appear that are specific to playtesting at the location. These fields can be
                filled out later when starting the playtest.
              </p>
            </div>
          </Collapse>
          <Alert variant="info">
            Scheduling info cannot be changed. <strong>Cancel</strong> the playtest then <strong>Copy</strong> it if you
            made a mistake.
          </Alert>
          <h5>🚦 Start a playtest</h5>
          <p>
            Press the <strong>Start</strong> button to the right of a scheduled playtest to start it. Be sure to check
            the number of <strong>Players</strong> that have joined before starting, as matchmaking stops once a
            playtest has started.
          </p>
          <p>
            You can change a few last minute details about the playtest before pressing the <strong>Start</strong>{' '}
            button at the bottom. Once started, all players that have joined the playtest will be shown the configured
            Discord link, Rules link, and Location specific instructions for how to access the playtest.
          </p>
          <Alert variant="warning">
            A playtest must be started within 15 minutes of its target start date or it will <strong>Expire</strong>.
          </Alert>
          <h5>🎊 Complete a playtest</h5>
          <p>
            Press the <strong>Complete</strong> button to the right of a started playtest to complete it. This will
            notify players that the playtest has ended, and award them with playtest points. You must complete all
            playtests before starting a new one.
          </p>
          <p>
            Playtesters are encouraged to participate for the full scheduled duration of the playtest, and may leave as
            soon as the time is up even if you haven't finished your game. They may have other commitments after your
            playtest, so be respectful of their time.
          </p>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function Designer() {
  const { createGame, listGames, updateGame } = useGameRepository();
  const { listMyPlaytests, createPlaytest, updatePlaytest } = usePlaytestRepository();

  const [canRefresh, setCanRefresh] = useState(true);

  const [games, setGames] = useState<Game[]>([]);
  const [listGamesCriteria, setListGamesCriteria] = useState<boolean>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (listGamesCriteria) {
        const games = await listGames();
        if (!ignore) {
          setGames(games);
          setListGamesCriteria(undefined);
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [listGamesCriteria, listGames]);

  const [showGameForm, setShowGameForm] = useState(false);
  const [gameFormError, setGameFormError] = useState<string>();
  const [createGameCriteria, setCreateGameCriteria] = useState<Partial<Game>>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (createGameCriteria) {
        const { gameId, ...createGameRequest } = createGameCriteria;
        const newGameResult = await createGame(createGameRequest);
        if (!ignore) {
          const newGame = newGameResult.value;
          if (newGame) {
            setGames([newGame, ...games]);
            setCreateGameCriteria(undefined);
            setShowGameForm(false);
            setGameFormError(undefined);
          } else {
            setGameFormError(buildFriendlyErrorMessage(newGameResult.error));
          }
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [createGameCriteria, createGame]);

  const [updateGameCriteria, setUpdateGameCriteria] = useState<Partial<Game>>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (updateGameCriteria) {
        await updateGame(updateGameCriteria);
        if (!ignore) {
          const updatedGameIndex = games.findIndex((game) => game.gameId === updateGameCriteria.gameId);
          const updatedGame = Object.assign(games[updatedGameIndex], updateGameCriteria);
          setGames([...games.slice(0, updatedGameIndex), updatedGame, ...games.slice(updatedGameIndex + 1)]);
          setUpdateGameCriteria(undefined);
          setShowGameForm(false);
          setGameFormError(undefined);
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [updateGameCriteria, updateGame]);

  const [playtestSessions, setPlaytestSessions] = useState<PlaytestSession[]>([]);
  const [listPlaytestSessionsCriteria, setListPlaytestSessionsCriteria] = useState<boolean>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (listPlaytestSessionsCriteria) {
        const playtestSessions = await listMyPlaytests();
        if (!ignore && listPlaytestSessionsCriteria) {
          setPlaytestSessions(playtestSessions);
          setListPlaytestSessionsCriteria(undefined);
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [listPlaytestSessionsCriteria, listMyPlaytests]);

  const [showPlaytestSessionForm, setShowPlaytestSessionForm] = useState(false);
  const [playtestSessionFormError, setPlaytestSessionFormError] = useState<string>();
  const [createPlaytestSessionCriteria, setCreatePlaytestSessionCriteria] = useState<CreatePlaytestSessionRequest>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (createPlaytestSessionCriteria) {
        const newPlaytestSessionResult = await createPlaytest(createPlaytestSessionCriteria);
        if (!ignore) {
          const newPlaytestSession = newPlaytestSessionResult.value;
          if (newPlaytestSession) {
            setPlaytestSessions([newPlaytestSession, ...playtestSessions]);
            setCreatePlaytestSessionCriteria(undefined);
            setShowPlaytestSessionForm(false);
            setPlaytestSessionFormError(undefined);
          } else {
            setPlaytestSessionFormError(buildFriendlyErrorMessage(newPlaytestSessionResult.error));
          }
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [createPlaytestSessionCriteria, createPlaytest]);

  const [showPlaytestStartForm, setShowPlaytestStartForm] = useState(false);
  const [playtestSessionStartFormDetails, setPlaytestSessionStartFormDetails] = useState<PlaytestSessionPatch>();
  const [updatePlaytestSessionCriteria, setUpdatePlaytestSessionCriteria] = useState<PlaytestSessionPatch>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (updatePlaytestSessionCriteria) {
        const { playableType, ...patchRequest } = updatePlaytestSessionCriteria;
        await updatePlaytest(patchRequest);
        if (!ignore) {
          const updatedPlaytestIndex = playtestSessions.findIndex(
            (playtest) => playtest.playtestSessionId === updatePlaytestSessionCriteria.playtestSessionId
          );
          const updatedPlaytest = Object.assign(playtestSessions[updatedPlaytestIndex], updatePlaytestSessionCriteria);
          setPlaytestSessions([
            ...playtestSessions.slice(0, updatedPlaytestIndex),
            updatedPlaytest,
            ...playtestSessions.slice(updatedPlaytestIndex + 1)
          ]);
          setUpdatePlaytestSessionCriteria(undefined);
          setPlaytestSessionStartFormDetails(undefined);
          setShowPlaytestStartForm(false);
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [updatePlaytestSessionCriteria, createPlaytest]);

  // Refresh active playtest from server every 1 minute
  useEffect(() => {
    const comInterval = setInterval(() => {
      const activePlaytestSessions = playtestSessions.filter(
        (playtest) =>
          dayjs(playtest.startDate || playtest.targetStartDate).isBefore(dayjs().add(15, 'minutes')) &&
          dayjs(playtest.expirationDate).isAfter(dayjs()) &&
          ['ready', 'started'].includes(playtest.status)
      );
      if (activePlaytestSessions.length && !listPlaytestSessionsCriteria) {
        setListPlaytestSessionsCriteria(true);
      }
    }, 60_000);
    return () => clearInterval(comInterval);
  }, [playtestSessions, listPlaytestSessionsCriteria]);

  useEffect(() => {
    setListGamesCriteria(true);
    setListPlaytestSessionsCriteria(true);

    // Disable refreshing after 1 hour on page
    const comInterval = setInterval(() => {
      setCanRefresh(false);
    }, 3_600_000); // 1 hour
    return () => clearInterval(comInterval);
  }, []);

  return (
    <>
      <TutorialButton />
      <Container>
        {!canRefresh && (
          <Alert className="mt-3" variant="info">
            You have gone inactive after 1 hour of inactivity. Refresh the page.
          </Alert>
        )}
        <GamesList
          games={games}
          onCreate={(item) => {
            setCreateGameCriteria(item);
          }}
          onUpdate={(item) => {
            setUpdateGameCriteria(item);
          }}
          showForm={showGameForm}
          setShowForm={setShowGameForm}
          error={gameFormError}
        />
        <PlaytestSessionsList
          games={games}
          playtestSessions={playtestSessions}
          onCreate={(item) => {
            const { dateOfTargetStartDate, timeOfTargetStartDate, durationMinutes, ...itemWithoutTemps } = item;
            const request: CreatePlaytestSessionRequest = {
              ...itemWithoutTemps,
              targetStartDate: dayjs(
                `${item.dateOfTargetStartDate}T${item.timeOfTargetStartDate}:00.000`
              ).toISOString(),
              durationSeconds: item.durationMinutes * 60
            };
            setPlaytestSessionFormError(undefined);
            setCreatePlaytestSessionCriteria(request);
          }}
          onCancel={(item) => {
            setUpdatePlaytestSessionCriteria({ playtestSessionId: item.playtestSessionId, status: 'canceled' });
          }}
          onStart={(item) => {
            setPlaytestSessionStartFormDetails({
              playtestSessionId: item.playtestSessionId,
              status: 'started',
              discordInviteLink: item.discordInviteLink,
              usePlayableCommunication: item.usePlayableCommunication,
              rulesLink: item.rulesLink,
              usePlayableRules: item.usePlayableRules,
              playableType: item.playableType,
              tabletopSimulatorPassword: item.tabletopSimulatorPassword,
              tabletopSimulatorRoomName: item.tabletopSimulatorRoomName,
              tabletopiaJoinLink: item.tabletopiaJoinLink,
              playingCardsIOJoinLink: item.playingCardsIOJoinLink,
              screentopGGJoinLink: item.screentopGGJoinLink,
              itchIOLink: item.itchIOLink,
            });
            setShowPlaytestStartForm(true);
          }}
          onComplete={(item) => {
            setUpdatePlaytestSessionCriteria({ playtestSessionId: item.playtestSessionId, status: 'completed' });
          }}
          showForm={showPlaytestSessionForm}
          setShowForm={(show) => {
            setPlaytestSessionFormError(undefined);
            setShowPlaytestSessionForm(show);
          }}
          error={playtestSessionFormError}
        />
        <PlaytestStartForm
          onStart={(item) => {
            if (item) {
              setUpdatePlaytestSessionCriteria(item);
            }
          }}
          show={showPlaytestStartForm}
          onShow={setShowPlaytestStartForm}
          selectedItem={playtestSessionStartFormDetails}
          onSelectItem={setPlaytestSessionStartFormDetails}
        />
      </Container>
    </>
  );
}

function buildFriendlyErrorMessage(error: ErrorResponse | undefined) {
  if (!error) {
    return `An unknown error has occurred.`;
  }
  const firstError = error.errors?.[0];
  switch (firstError.property) {
    case 'playtestPoints':
      return `Insufficient playtest points.`;
    case 'targetStartDate':
      return `${firstError.message}`;
    default:
      return `"${firstError.actual}" is not an allowed value for ${firstError.property}.`;
  }
}

function GamesList({
  games,
  onCreate,
  onUpdate,
  showForm,
  setShowForm,
  error
}: {
  games: Game[];
  onCreate: (game: Game) => void;
  onUpdate: (game: Game) => void;
  showForm: boolean;
  setShowForm: (show: boolean) => void;
  error?: string | undefined;
}) {
  const GAME_NEW: Game = { gameId: '', name: '' };

  const typeOptions = [
    { label: 'Tabletop Game', value: 'boardgame' },
    { label: 'Video Game', value: 'videogame' }
  ];
  const [selectedItem, setSelectedItem] = useState<Game>();

  return (
    <div className="mt-3">
      <h2>Games</h2>
      <DTableWithActions
        items={games}
        onCreate={onCreate}
        onUpdate={onUpdate}
        defaultItem={GAME_NEW}
        showForm={showForm}
        onShowForm={setShowForm}
        selectedItem={selectedItem}
        onSelectItem={setSelectedItem}
        error={error}
        itemKey={(item) => item.gameId}
        size="sm"
        fields={{
          name: {
            header: 'Name',
            placeholder: '',
            description: '',
            cell: (item: Game) => <>{item.name}</>,
            showOnList: true,
            showOnForm: true
          },
          gameType: {
            header: 'Type',
            placeholder: '',
            description: '',
            cell: (item: Game) => <>{typeOptions.find((option) => option.value === item.gameType)?.label}</>,
            options: typeOptions,
            showOnList: true,
            showOnForm: true
          },
          description: {
            header: 'Description',
            placeholder: 'Dragons sailing boats',
            description: '',
            cell: (item: Game) => <>{item.description}</>,
            showOnList: false,
            showOnForm: true
          }
        }}
      />
    </div>
  );
}

interface CreatePlaytestSessionFormRequest
  extends Omit<CreatePlaytestSessionRequest, 'targetStartDate' | 'durationSeconds'> {
  dateOfTargetStartDate: string;
  timeOfTargetStartDate: string;
  durationMinutes: number;
}

function PlaytestSessionsList({
  games,
  playtestSessions,
  onCreate,
  onStart,
  onCancel,
  onComplete,
  showForm,
  setShowForm,
  error
}: {
  games: Game[];
  playtestSessions: PlaytestSession[];
  onCreate: (playtestSession: CreatePlaytestSessionFormRequest) => void;
  onStart: (playtestSession: PlaytestSession) => void;
  onCancel: (playtestSession: PlaytestSession) => void;
  onComplete: (playtestSession: PlaytestSession) => void;
  showForm: boolean;
  setShowForm: (show: boolean) => void;
  error?: string | undefined;
}) {
  const PLAYTEST_SESSION_NEW: CreatePlaytestSessionFormRequest = {
    gameId: '',
    gameVersion: '',
    durationMinutes: 60,
    dateOfTargetStartDate: dayjs().format('YYYY-MM-DD'),
    timeOfTargetStartDate: dayjs().format('HH:mm'),
    playerCountTarget: 4,
    tier: 'bronze',
    playtestType: 'guided',
    discordInviteLink: '',
    usePlayableCommunication: true,
    rulesLink: '',
    usePlayableRules: true,
    playableType: 'tabletopia',
    tabletopiaJoinLink: '',
    tabletopSimulatorRoomName: '',
    tabletopSimulatorPassword: '',
    playingCardsIOJoinLink: '',
    screentopGGJoinLink: '',
    itchIOLink: ''
  };

  const gameOptions = games.map((game) => ({ label: game.name, value: game.gameId }));
  const [selectedItem, setSelectedItem] = useState<CreatePlaytestSessionFormRequest>();

  return (
    <div className="mt-3">
      <h2>Playtest Sessions</h2>
      <DTableWithActions
        items={playtestSessions
          .map((playtestSession) => ({
            ...playtestSession,
            dateOfTargetStartDate: dayjs(playtestSession.targetStartDate).format('YYYY-MM-DD'),
            timeOfTargetStartDate: dayjs(playtestSession.targetStartDate).format('HH:mm'),
            durationMinutes: playtestSession.durationSeconds / 60
          }))
          .sort(
            (a, b) =>
              new Date(b.startDate || b.targetStartDate).valueOf() -
              new Date(a.startDate || a.targetStartDate).valueOf()
          )}
        onCreate={onCreate}
        defaultItem={PLAYTEST_SESSION_NEW}
        showForm={showForm}
        onShowForm={setShowForm}
        selectedItem={selectedItem}
        onSelectItem={setSelectedItem}
        error={error}
        itemKey={(item) => item.playtestSessionId || ''}
        size="sm"
        fields={{
          gameId: {
            header: 'Game',
            placeholder: '',
            description: '',
            cell: (item) => <>{gameOptions.find((option) => option.value === item.gameId)?.label}</>,
            showOnList: true,
            showOnForm: true,
            options: gameOptions
          },
          gameVersion: {
            header: 'Version',
            placeholder: '',
            description: '',
            cell: (item) => <>{item.gameVersion}</>,
            showOnList: true,
            showOnForm: true
          },
          durationMinutes: {
            header: 'Duration',
            placeholder: '',
            description: 'The number of minutes the playtest will last for.',
            cell: (item) => <>{item.durationMinutes} minutes</>,
            type: 'number',
            showOnList: true,
            showOnForm: true
          },
          dateOfTargetStartDate: {
            header: 'Target Start Date',
            placeholder: '',
            description: '',
            type: 'date',
            cell: (item) => <>{dayjs(item.dateOfTargetStartDate).format('L')}</>,
            showOnList: true,
            showOnForm: true
          },
          timeOfTargetStartDate: {
            header: 'Target Start Time',
            placeholder: '',
            description: '',
            type: 'time',
            cell: (item) => <>{dayjs('1/1/1 ' + item.timeOfTargetStartDate).format('LT')}</>,
            showOnList: true,
            showOnForm: true
          },
          playerCountTarget: {
            header: 'Players',
            placeholder: '',
            description: 'The number of players to find, excluding the host.',
            type: 'number',
            cell: (item) => {
              const fullPlaytest = item as unknown as PlaytestSession;
              return (
                <>
                  {fullPlaytest.joinedUsers ? Object.keys(fullPlaytest.joinedUsers).length : '0'}/
                  {item.playerCountTarget}
                </>
              );
            },
            showOnList: true,
            showOnForm: true
          },
          playableType: {
            header: 'Location',
            placeholder: 'Tabletopia',
            description: 'Where will users play the game?',
            cell: (item) => <>{item.playableType}</>,
            options: [
              { label: 'Tabletopia', value: 'tabletopia' },
              { label: 'PlayingCards.io', value: 'playingCardsIO' },
              { label: 'Screentop.gg', value: 'screentopGG' },
              { label: 'Tabletop Simulator', value: 'tabletopSimulator' },
              { label: 'Itch.io', value: 'itchIO' }
            ],
            showOnList: false,
            showOnForm: true
          },
          tabletopiaJoinLink: {
            header: 'Tabletopia Join Link',
            placeholder: '',
            description: '',
            cell: (item) => <>{item.tabletopiaJoinLink}</>,
            showOnList: false,
            showOnForm: true,
            conditional: (item) => item.playableType === 'tabletopia'
          },
          tabletopSimulatorRoomName: {
            header: 'TTS Room Name',
            placeholder: '',
            description: '',
            cell: (item) => <>{item.tabletopSimulatorRoomName}</>,
            showOnList: false,
            showOnForm: true,
            conditional: (item) => item.playableType === 'tabletopSimulator'
          },
          tabletopSimulatorPassword: {
            header: 'TTS Password',
            placeholder: '',
            description: '',
            cell: (item) => <>{item.tabletopSimulatorPassword}</>,
            showOnList: false,
            showOnForm: true,
            conditional: (item) => item.playableType === 'tabletopSimulator'
          },
          playingCardsIOJoinLink: {
            header: 'PlayingCards.io Join Link',
            placeholder: '',
            description: '',
            cell: (item) => <>{item.playingCardsIOJoinLink}</>,
            showOnList: false,
            showOnForm: true,
            conditional: (item) => item.playableType === 'playingCardsIO'
          },
          screentopGGJoinLink: {
            header: 'Screentop.gg Join Link',
            placeholder: '',
            description: '',
            cell: (item) => <>{item.screentopGGJoinLink}</>,
            showOnList: false,
            showOnForm: true,
            conditional: (item) => item.playableType === 'screentopGG'
          },
          itchIOLink: {
            header: 'Itch.io Link',
            placeholder: '',
            description: '',
            cell: (item) => <>{item.itchIOLink}</>,
            showOnList: false,
            showOnForm: true,
            conditional: (item) => item.playableType === 'itchIO'
          },
          tier: {
            header: 'Matchmaking Tier',
            placeholder: '',
            descriptionFn: (item) => {
              switch (item.tier) {
                default:
                  return 'What is the priority of this playtest during matchmaking?';
                case 'disabled':
                  return 'Matchmaking is disabled for this playtest.';
                case 'bronze':
                  return (
                    <>
                      <strong>Cost:</strong> 0 pp
                      <p>
                        Bronze playtests are the lowest priority playtest when assigning players during matchmaking.
                      </p>
                    </>
                  );
                case 'silver':
                  return (
                    <>
                      <strong>Cost:</strong> {item.playerCountTarget * item.durationMinutes * 6} pp (
                      {item.playerCountTarget} players x {item.durationMinutes} minutes)
                      <p>Silver playtests are prioritized over other Bronze playtests scheduled within 15 minutes.</p>
                    </>
                  );
              }
            },
            cell: (item) => <>{item.tier}</>,
            options: [
              { label: 'Disabled', value: 'disabled' },
              { label: 'Bronze', value: 'bronze' },
              { label: 'Silver', value: 'silver' }
            ],
            showOnList: true,
            showOnForm: true
          },
          playtestType: {
            header: 'Type',
            placeholder: '',
            description: 'Will this playtest be guided by a host?',
            cell: (item) => <>{item.playtestType}</>,
            options: [{ label: 'Guided', value: 'guided' }],
            showOnList: false,
            showOnForm: true
          },
          discordInviteLink: {
            header: 'Discord Invite Link',
            placeholder: '',
            description: 'An invite link to the Discord used during the playtest.',
            cell: (item) => <>{item.discordInviteLink}</>,
            showOnList: false,
            showOnForm: true
          },
          usePlayableCommunication: {
            header: 'Use Built-In Communication',
            placeholder: '',
            description: 'Will this playtest session use chat or voice built into the playtest?',
            cell: (item) => <>{item.usePlayableCommunication}</>,
            type: 'boolean',
            options: [
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' }
            ],
            showOnList: false,
            showOnForm: true
          },
          rulesLink: {
            header: 'Rules Link',
            placeholder: '',
            description:
              'An external link to the rules used for the game (Only Google Documents are currently supported)',
            cell: (item) => <>{item.rulesLink}</>,
            showOnList: false,
            showOnForm: true
          },
          usePlayableRules: {
            header: 'Use Built-In Rules',
            placeholder: '',
            description: 'Will this playtest session have the rules built into the playtest?',
            cell: (item) => <>{item.usePlayableRules}</>,
            type: 'boolean',
            options: [
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' }
            ],
            showOnList: false,
            showOnForm: true
          },
          playtestStatusActions: {
            header: 'Status',
            cell: (item) => {
              const fullPlaytest = item as unknown as PlaytestSession;
              return (
                <>
                  {fullPlaytest?.status === 'started' && (
                    <>
                      Ends {dayjs(fullPlaytest.startDate).add(fullPlaytest.durationSeconds, 'seconds').fromNow()}
                      <Button className="mx-1" variant="success" onClick={() => onComplete?.(fullPlaytest)}>
                        Complete
                      </Button>
                    </>
                  )}
                  {fullPlaytest?.status === 'ready' && (
                    <>
                      Expires {dayjs(fullPlaytest.expirationDate).fromNow()}
                      <Button className="mx-1" onClick={() => onStart?.(fullPlaytest)}>
                        Start
                      </Button>
                    </>
                  )}
                  {fullPlaytest?.status === 'ready' && (
                    <Button className="mx-1" variant="danger" onClick={() => onCancel?.(fullPlaytest)}>
                      Cancel
                    </Button>
                  )}
                  {!['started', 'ready'].includes(fullPlaytest?.status) && <strong>{fullPlaytest.status}</strong>}
                </>
              );
            },
            showOnList: true,
            showOnForm: false
          },
          playtestMetaActions: {
            header: '',
            cell: (item) => {
              return (
                <Button
                  className="mx-1"
                  variant="info"
                  onClick={() => {
                    const copy = structuredClone({
                      ...item,
                      playtestSessionId: undefined,
                      dateOfTargetStartDate: dayjs().format('YYYY-MM-DD'),
                      timeOfTargetStartDate: dayjs().format('HH:mm')
                    } as CreatePlaytestSessionFormRequest);
                    const propertiesToKeep = Object.keys(PLAYTEST_SESSION_NEW);
                    for (const key in copy) {
                      if (!propertiesToKeep.includes(key)) {
                        delete (copy as { [key: string]: any })[key];
                      }
                    }
                    setSelectedItem(copy);
                    setShowForm(true);
                  }}
                >
                  Copy
                </Button>
              );
            },
            showOnList: true,
            showOnForm: false
          }
        }}
      />
    </div>
  );
}

interface PlaytestStartFormProps {
  onStart: (playtestSession: PlaytestSessionPatch | undefined) => void;
  show?: boolean;
  onShow?: (show: boolean) => void;
  selectedItem?: PlaytestSessionPatch | undefined;
  onSelectItem?: (item: PlaytestSessionPatch | undefined) => void;
}

function PlaytestStartForm(props: PlaytestStartFormProps) {
  return (
    <Modal show={props.show} onHide={() => props.onShow?.(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Start Playtest</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DForm
          item={props.selectedItem}
          setItem={props.onSelectItem || (() => {})}
          fields={{
            discordInviteLink: {
              header: 'Discord Invite Link',
              placeholder: '',
              description: 'An invite link to the Discord used during the playtest.',
              cell: (item) => <>{item.discordInviteLink}</>,
              showOnList: false,
              showOnForm: true
            },
            usePlayableCommunication: {
              header: 'Use Built-In Communication',
              placeholder: '',
              description: 'Will this playtest session use chat or voice built into the playtest?',
              cell: (item) => <>{item.usePlayableCommunication}</>,
              type: 'boolean',
              options: [
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' }
              ],
              showOnList: false,
              showOnForm: true
            },
            rulesLink: {
              header: 'Rules Link',
              placeholder: '',
              description:
                'An external link to the rules used for the game (Only Google Documents are currently supported)',
              cell: (item) => <>{item.rulesLink}</>,
              showOnList: false,
              showOnForm: true
            },
            usePlayableRules: {
              header: 'Use Built-In Rules',
              placeholder: '',
              description: 'Will this playtest session have the rules built into the playtest?',
              cell: (item) => <>{item.usePlayableRules}</>,
              type: 'boolean',
              options: [
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' }
              ],
              showOnList: false,
              showOnForm: true
            },
            tabletopiaJoinLink: {
              header: 'Tabletopia Join Link',
              placeholder: '',
              description: '',
              cell: (item) => <>{item.tabletopiaJoinLink}</>,
              showOnList: false,
              showOnForm: true,
              conditional: (item) => item.playableType === 'tabletopia'
            },
            tabletopSimulatorRoomName: {
              header: 'TTS Room Name',
              placeholder: '',
              description: '',
              cell: (item) => <>{item.tabletopSimulatorRoomName}</>,
              showOnList: false,
              showOnForm: true,
              conditional: (item) => item.playableType === 'tabletopSimulator'
            },
            tabletopSimulatorPassword: {
              header: 'TTS Password',
              placeholder: '',
              description: '',
              cell: (item) => <>{item.tabletopSimulatorPassword}</>,
              showOnList: false,
              showOnForm: true,
              conditional: (item) => item.playableType === 'tabletopSimulator'
            },
            playingCardsIOJoinLink: {
              header: 'PlayingCards.io Join Link',
              placeholder: '',
              description: '',
              cell: (item) => <>{item.playingCardsIOJoinLink}</>,
              showOnList: false,
              showOnForm: true,
              conditional: (item) => item.playableType === 'playingCardsIO'
            },
            screentopGGJoinLink: {
              header: 'Screentop.gg Join Link',
              placeholder: '',
              description: '',
              cell: (item) => <>{item.screentopGGJoinLink}</>,
              showOnList: false,
              showOnForm: true,
              conditional: (item) => item.playableType === 'screentopGG'
            },
            itchIOLink: {
              header: 'Itch.io Link',
              placeholder: '',
              description: '',
              cell: (item) => <>{item.itchIOLink}</>,
              showOnList: false,
              showOnForm: true,
              conditional: (item) => item.playableType === 'itchIO'
            }
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onShow?.(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={() => props.onStart?.(props.selectedItem)}>
          Start
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Designer;
