import { useParams } from 'react-router-dom';
import '../App.css';
import { Container, Stack } from 'react-bootstrap';

function Game() {
  const { gameId } = useParams();
  return (
    <Container>
      Game - {gameId}
      <Stack gap={2}>
      </Stack>
    </Container>
  );
}

export default Game;
