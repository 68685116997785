import { useCallback, useMemo } from 'react';
import {
  ErrorResponse,
  JoinPlaytestSessionRequest,
  JoinedPlaytestSession,
  LeavePlaytestSessionRequest,
  ListMatchmakingPlaytestSessionsRequest,
  MatchmakingPlaytestSession,
  MatchmakingPlaytestSessionDetails,
  PlayableType,
  PlaytestSession,
  PlaytestSessionPatch
} from '../../../models';
import { RepositoryResult, useSimpleRepository } from './SimpleRepository';

export interface UsePlaytestRepositoryResult {
  listMatchmakingPlaytests: (request?: ListMatchmakingPlaytestSessionsRequest) => Promise<MatchmakingPlaytestSession[]>;
  joinMatchmakingPlaytest: (request: JoinPlaytestSessionRequest) => Promise<RepositoryResult<boolean>>;
  leaveMatchmakingPlaytest: (request: LeavePlaytestSessionRequest) => Promise<void>;
  listJoinedPlaytests: () => Promise<JoinedPlaytestSession[]>;
  getJoinedPlaytest: (playtestId: string) => Promise<MatchmakingPlaytestSessionDetails | undefined>;
  createPlaytest: (request: Partial<PlaytestSession>) => Promise<RepositoryResult<PlaytestSession | undefined>>;
  updatePlaytest: (request: PlaytestSessionPatch) => Promise<void>;
  listMyPlaytests: () => Promise<PlaytestSession[]>;
  listUserPlaytests: (username: string) => Promise<MatchmakingPlaytestSession[]>;
}

export const usePlaytestRepository = (): UsePlaytestRepositoryResult => {
  const { send } = useSimpleRepository();

  const listMatchmakingPlaytests = useCallback(
    async (request?: ListMatchmakingPlaytestSessionsRequest): Promise<MatchmakingPlaytestSession[]> => {
      try {
        const playableTypes =
          request?.playableTypes?.map((playableType: PlayableType) => ['playableTypes', playableType]) || [];
        const response = await send('GET', `/api/playtests?${new URLSearchParams(playableTypes)}`);
        if (response.ok) {
          return JSON.parse(response.body) as MatchmakingPlaytestSession[];
        } else {
          console.warn(response.body);
          return [];
        }
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    [send]
  );

  const listUserPlaytests = useCallback(
    async (username: string): Promise<MatchmakingPlaytestSession[]> => {
      try {
        const response = await send('GET', `/api/users/${username}/playtests`);
        if (response.ok) {
          return JSON.parse(response.body) as MatchmakingPlaytestSession[];
        } else {
          console.warn(response.body);
          return [];
        }
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    [send]
  );

  const joinPlaytest = useCallback(
    async (request: JoinPlaytestSessionRequest): Promise<RepositoryResult<boolean>> => {
      try {
        const response = await send('POST', '/api/users/me/joined-playtests', JSON.stringify(request));
        if (!response.ok) {
          console.warn(response.body);
          return { value: false, error: JSON.parse(response.body) as ErrorResponse };
        }
        return { value: true, error: undefined };
      } catch (e) {
        console.error(e);
        return { value: false, error: undefined };
      }
    },
    [send]
  );

  const leavePlaytest = useCallback(
    async (request: LeavePlaytestSessionRequest): Promise<void> => {
      try {
        const response = await send('DELETE', '/api/users/me/joined-playtests', JSON.stringify(request));
        if (!response.ok) {
          console.warn(response.body);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [send]
  );

  const listJoinedPlaytests = useCallback(async (): Promise<JoinedPlaytestSession[]> => {
    try {
      const response = await send('GET', '/api/users/me/joined-playtests');
      if (response.ok) {
        return JSON.parse(response.body) as JoinedPlaytestSession[];
      } else {
        console.warn(response.body);
        return [];
      }
    } catch (e) {
      console.error(e);
      return [];
    }
  }, [send]);

  const getJoinedPlaytest = useCallback(
    async (playtestId: string): Promise<MatchmakingPlaytestSessionDetails | undefined> => {
      try {
        const response = await send('GET', `/api/users/me/joined-playtests/${playtestId}`);
        if (response.ok) {
          return JSON.parse(response.body) as MatchmakingPlaytestSessionDetails;
        } else {
          console.warn(response.body);
          return undefined;
        }
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    [send]
  );

  const createPlaytest = useCallback(
    async (request: Partial<PlaytestSession>): Promise<RepositoryResult<PlaytestSession | undefined>> => {
      try {
        const response = await send('POST', '/api/users/me/playtests', JSON.stringify(request));
        if (response.ok) {
          return { value: JSON.parse(response.body) as PlaytestSession };
        } else {
          console.warn(response.body);
          return { value: undefined, error: JSON.parse(response.body) as ErrorResponse };
        }
      } catch (e) {
        console.error(e);
        return { value: undefined, error: undefined };
      }
    },
    [send]
  );

  const updatePlaytest = useCallback(
    async (request: PlaytestSessionPatch): Promise<void> => {
      try {
        const { playtestSessionId, ...updateRequest } = request;
        const response = await send(
          'PATCH',
          `/api/users/me/playtests/${request.playtestSessionId}`,
          JSON.stringify(updateRequest)
        );
        if (!response.ok) {
          console.warn(response.body);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [send]
  );

  const listMyPlaytests = useCallback(async (): Promise<PlaytestSession[]> => {
    try {
      const response = await send('GET', '/api/users/me/playtests');
      if (response.ok) {
        return JSON.parse(response.body) as PlaytestSession[];
      } else {
        console.warn(response.body);
        return [];
      }
    } catch (e) {
      console.error(e);
      return [];
    }
  }, [send]);

  return useMemo(
    () => ({
      listMatchmakingPlaytests,
      joinMatchmakingPlaytest: joinPlaytest,
      listJoinedPlaytests,
      leaveMatchmakingPlaytest: leavePlaytest,
      getJoinedPlaytest,
      createPlaytest,
      updatePlaytest,
      listMyPlaytests,
      listUserPlaytests
    }),
    [
      listMatchmakingPlaytests,
      joinPlaytest,
      listJoinedPlaytests,
      leavePlaytest,
      getJoinedPlaytest,
      createPlaytest,
      updatePlaytest,
      listMyPlaytests,
      listUserPlaytests
    ]
  );
};
