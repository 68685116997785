import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useMemo } from "react";

const LAMBDA_URI = 'https://2wns5ds9z2.execute-api.us-east-1.amazonaws.com/prod/items'; // 'https://2hbjgjn8ki.execute-api.us-east-2.amazonaws.com/Prod/tests'

export interface GetOrganizationsResult {
    items: Organization[];
}

export interface Organization {
    id: string;
    name: string;
}

export interface UseItemsRepositoryResult<T> {
    getItems: () => Promise<T[]>;
    updateItem: (id: string, item: T) => Promise<void>;
    createItem: (item: T) => Promise<void>;
}

export const useItemsRepository = <T,>(): UseItemsRepositoryResult<T> => {
    const { getAccessTokenSilently } = useAuth0();

    const createItem = useCallback(async (item: T): Promise<void> => {
        try {
            const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: 'https://playtest-exchange-api-gateway.com',
                        //scope: 'read:posts',
                    },
                });
            await fetch(LAMBDA_URI, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(item),
            });
            return;
        } catch (e) {
            // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
            console.error(e);
            return;
        }
    }, [getAccessTokenSilently]);

    const getItems = useCallback(async (): Promise<T[]> => {
        try {
            const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: 'https://playtest-exchange-api-gateway.com',
                        //scope: 'read:posts',
                    },
                });
            const response = await fetch(LAMBDA_URI, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return await response.json() as T[];
        } catch (e) {
            // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
            console.error(e);
            return [];
        }
    }, [getAccessTokenSilently]);

    const updateItem = useCallback(async (id: string, item: T): Promise<void> => {
        try {
            const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: 'https://playtest-exchange-api-gateway.com',
                        //scope: 'read:posts',
                    },
                });
            await fetch(LAMBDA_URI + `/${id}`, {
                method: 'PATCH',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(item),
            });
            return;
        } catch (e) {
            // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
            console.error(e);
            return;
        }
    }, [getAccessTokenSilently]);

    return useMemo(() => ({ createItem, getItems, updateItem }), [createItem, getItems, updateItem]);
}