import '../App.css';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <Container>
      <h1>NotFound</h1>
      <p><Link to="/">Go home Sam</Link></p>
    </Container>
  );
}

export default NotFound;
